import { css, SerializedStyles } from "@emotion/react";
import { queries } from "@songtradr/spa-common/lib/utils";
import theme from "../theme";

export default {
  app: css`
    display: flex;
    align-items: center;
    min-height: 100vh;
    min-width: 100%;
    flex-direction: column;
    background-color: ${theme.colors.background.basalt};

    @media ${queries.xxLarge} {
      align-items: center;
    }
  `,
  backBtnContainer: css`
    display: flex;
    align-items: center;
    margin: 3rem 0;
  `,
  map: css`
   width: 500px;
   height: 100px;
   padding-top: 3px;
   border-radius: 10px;
   z-index: 1;

   .leaflet-container {
     border-radius: 10px;
   },
  `,
  header: css`
    margin-bottom: 2rem !important;
  `,
  content: css`
    background-color: ${theme.colors.background.basalt};
    width: 100%;
    max-width: 1440px;
    padding: 0 3rem;
  `,
  footer: css`
    background-color: ${theme.colors.background.basalt};
  `,
  logoContainer: css`
    display: flex;
    margin-top: 64px;
    margin-left: 0;
    margin-bottom: 128px;
    width: 100%;
    z-index: 1000;
  `,
  orgLogoContainer: css`
    z-index: 100;
  `,
  animationBg: css`
    position: absolute;
    right: 0;
  `,
  orgLogo: css`
    height: 100%;
    object-fit: cover;
    display: block;
    width: auto;
    max-height: 165px;
  `,
  trackContainer(width: number): SerializedStyles {
    return css`
      background: ${theme.colors.secondary.darkGrayCardBackground};
      border-radius: 10px;
      width: ${width}%;
      margin-bottom: 16px;
      .track {
        display: block;
        padding: 20px;
      }
    `;
  },
  fileUploaderContainer(width: number): SerializedStyles {
    return css`
      background: ${theme.colors.secondary.darkGrayCardBackground};
      border-radius: 10px;
      width: ${width}%;
    `;
  },
  iconButton: css`
    background-color: transparent;
    border: none;
    &:hover {
      cursor: pointer;
      svg path {
        fill: ${theme.colors.secondary.brightOrange};
      }
    }
  `,
};
