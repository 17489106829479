/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";
import { Button } from "antd";
import ContentHeader from "../content-header";
import style from "./styles";
import config from "../../config";

const NoAccessScreen = (): ReactElement => {
  const goToEnterprise = () => {
    const url = window.location.href;
    const encodedUrl = encodeURIComponent(url);
    window.location.replace(`${config.enterprise.url}/login/${encodedUrl}`);
  };

  return (
    <div>
      <ContentHeader />
      <div css={style.ctaContainer}>
        <div css={style.headline}>Nothing to see here!</div>
        <div css={style.body}>
          You need to sign in to Songratdr Studio to view this page
        </div>
        <Button css={style.button} onClick={() => goToEnterprise()}>
          Sign in
        </Button>
      </div>
    </div>
  );
};

export default NoAccessScreen;
