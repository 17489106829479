/** @jsxImportSource @emotion/react */
import { Menu, Dropdown } from "antd";
import config from "src/config";
import { useTranslation } from "react-i18next";
import { useAuth } from "src/CognitoAuth/useAuth";
import Subscriptions from "src/constants";
import { ReactElement, useState } from "react";
import { getIsMobile, useWindowSize } from "@songtradr/spa-common";
import { CloseOutlined } from "@ant-design/icons";
import MenuIcon from "src/assets/icons/menu-icon";
import {
  generateSearchLink,
  generateLibraryLink,
  generateSonicBrandingLink,
} from "./utils";
import styles from "../navigation-bar/styles";

interface IMenuLink {
  label?: ReactElement;
  key?: string;
  type?: string;
}
const renderIcon = (isMobile: boolean, isDropdownOpen: boolean) => {
  return isDropdownOpen && isMobile ? <CloseOutlined /> : <MenuIcon />;
};

const CustomMenu = (menuItems: IMenuLink[]) => {
  const { logout } = useAuth();
  const { t } = useTranslation();

  return (
    <Menu css={styles.dropdownMenu}>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {menuItems.map((item, index) => {
        return item.type === "divider" ? (
          // eslint-disable-next-line react/no-array-index-key
          <Menu.Divider key={`divider-${index}`} />
        ) : (
          <Menu.Item key={item.key}>{item.label}</Menu.Item>
        );
      })}
      <Menu.Item
        key="support"
        className="support-link"
        data-testid="nav-link-support"
      >
        <a
          href={config.support.url}
          data-heapid="nav-link-support"
          target="_blank"
          rel="noreferrer"
        >
          {t("Help center")}
        </a>
      </Menu.Item>
      <Menu.Item
        onClick={logout}
        key="signOut"
        className="sign-out-link"
        data-testid="nav-link-sign-out"
        data-heapid="nav-link-sign-out"
      >
        {t("Sign Out")}
      </Menu.Item>
    </Menu>
  );
};

const HamburgerMenu = (): JSX.Element => {
  const { userPermissions, organisationId, isSuperAdmin } = useAuth();
  const isMobile = getIsMobile();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t } = useTranslation();
  useWindowSize();

  // hardcoding so the Loreal professional account doesnt show the search link
  const isLorealProfessional = organisationId === "6319cb6dc80bc8cf4fa3e25f";
  const navLinks = [];

  if (userPermissions.canAccessMusicSubscription) {
    if (!isLorealProfessional) {
      navLinks.push({
        label: (
          <a
            data-heapid="nav-link-search"
            data-testid="nav-link-search"
            href={generateSearchLink(organisationId)}
          >
            Search
          </a>
        ),
        key: "Search",
      });
    }
    // If org is not listed then use the new player: https://player.enterprise.songtradr.com
    if (!config.oldPlayerOrgIds.includes(organisationId)) {
      navLinks.push({
        label: (
          <a
            href={config.player.url}
            data-heapid="nav-link-playlists"
            data-testid="nav-link-playlists"
          >
            Playlists
          </a>
        ),
        key: Subscriptions.Library.name,
      });
    } else {
      navLinks.push({
        label: (
          <a
            data-heapid="nav-link-playlists"
            data-testid="nav-link-playlists"
            href={generateLibraryLink(organisationId)}
          >
            {Subscriptions.Library.name}
          </a>
        ),
        key: Subscriptions.Library.name,
      });
    }
  }
  if (userPermissions.canAccessInsights) {
    navLinks.push({
      label: (
        <a
          href={Subscriptions.Insights.path}
          onClick={() => setIsDropdownOpen(false)}
          data-heapid={`nav-link-${Subscriptions.Insights.name.toLowerCase()}`}
          data-testid={`nav-link-${Subscriptions.Insights.name.toLowerCase()}`}
        >
          {Subscriptions.Insights.name}
        </a>
      ),
      key: Subscriptions.Insights.name,
    });
  }
  if (userPermissions.canAccessProjects) {
    navLinks.push({
      label: (
        <a
          href={Subscriptions.Projects.path}
          onClick={() => setIsDropdownOpen(false)}
          data-heapid={`nav-link-${Subscriptions.Projects.name.toLowerCase()}`}
          data-testid={`nav-link-${Subscriptions.Projects.name.toLowerCase()}`}
        >
          {Subscriptions.Projects.name}
        </a>
      ),
      key: Subscriptions.Projects.name,
    });
  }
  if (userPermissions.canAccessMyContent) {
    navLinks.push({
      label: (
        <a
          data-heapid="nav-link-resources"
          data-testid="nav-link-resources"
          href={generateSonicBrandingLink(organisationId)}
        >
          Resources
        </a>
      ),
      key: Subscriptions.MyContent.name,
    });
  }
  if (userPermissions.canAccessValidate) {
    navLinks.push({
      label: (
        <a
          href={Subscriptions.Validate.path}
          onClick={() => setIsDropdownOpen(false)}
          data-heapid={`nav-link-${Subscriptions.Validate.name.toLowerCase()}`}
          data-testid={`nav-link-${Subscriptions.Validate.name.toLowerCase()}`}
        >
          {t(Subscriptions.Validate.name)}
        </a>
      ),
      key: Subscriptions.Validate.name,
    });
  }
  navLinks.push({
    type: "divider",
  });

  if (isSuperAdmin) {
    navLinks.push({
      label: (
        <a
          href={Subscriptions.OrganizationDashboard.path}
          onClick={() => setIsDropdownOpen(false)}
          data-testid="nav-link-admin-org-dash"
        >
          Organization dashboard
        </a>
      ),
      key: "nav-link-admin-org-dash",
    });
  }

  if (userPermissions.canAccessTeams) {
    navLinks.push({
      label: (
        <a
          href={Subscriptions.Team.path}
          onClick={() => setIsDropdownOpen(false)}
          data-heapid="nav-link-team"
          data-testid="nav-link-team"
        >
          {Subscriptions.Team.name}
        </a>
      ),
      key: Subscriptions.Team.name,
    });
  }
  const navigation = CustomMenu(navLinks);
  return (
    <Dropdown
      arrow={!isMobile ? { pointAtCenter: true } : false}
      overlay={navigation}
      onVisibleChange={() => setIsDropdownOpen((prevValue) => !prevValue)}
      trigger={["click"]}
      overlayStyle={{ width: isMobile ? "100%" : "220px" }}
      overlayClassName={`nav-menu-overlay ${isMobile ? "mobile" : "desktop"}`}
      placement={isMobile ? "topLeft" : "topRight"}
    >
      <div
        className="menu-icon-wrapper"
        data-heapid="header-nav-menu-icon"
        data-testid="header-nav-menu-icon"
      >
        {renderIcon(isMobile, isDropdownOpen)}
      </div>
    </Dropdown>
  );
};

export default HamburgerMenu;
