/* eslint-disable no-underscore-dangle */
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Layout } from "antd";
import { useAuth } from "src/CognitoAuth/useAuth";
import NoAccessScreen from "src/components/no-access";
import Content from "src/components/component-content";
import NavigationBar from "../../components/navigation-bar";
import LoadingSpinner from "../../components/loading-spinner";
import styles from "../../styles";
import { ISonicBranding } from "../../interfaces";
import config from "../../config";

function SonicBrandingContent() {
  const { brandingId } = useParams();
  const PROJECT_ID = config.sanityProjectID;
  const DATASET = config.sanityDataset;
  const QUERY = encodeURIComponent(`*[_id == "${brandingId}"]`);
  const URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`;

  const initialState = {
    sonicBranding: null,
  };
  const [state, setState] = useState<{ sonicBranding: ISonicBranding | null }>(
    initialState
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userHasAccess, setUserHasAccess] = useState<boolean>(false);

  const { getUserAccessToken } = useAuth();

  useEffect(() => {
    void (async () => {
      const accessToken = await getUserAccessToken();
      if (accessToken !== "") {
        setUserHasAccess(true);
      } else {
        setUserHasAccess(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      fetch(URL)
        .then((res) => res.json())
        .then(({ result }) => {
          if (result.length === 1) {
            setState({
              sonicBranding: result[0],
            });
          }
          setIsLoading(false);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          setIsLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [URL]
  );

  useEffect(() => {
    if (state.sonicBranding !== null) {
      document.title = state?.sonicBranding?.name;
    }
  }, [state]);

  if (isLoading) {
    return (
      <div>
        <Layout css={styles.app}>
          <Layout.Header css={styles.header}>
            <NavigationBar />
          </Layout.Header>
          <Layout.Content css={styles.content}>
            <LoadingSpinner />
          </Layout.Content>
          <Layout.Footer css={styles.footer} />
        </Layout>
      </div>
    );
  }

  return (
    <div>
      <Layout css={styles.app}>
        <Layout.Header css={styles.header}>
          <NavigationBar />
        </Layout.Header>
        <Layout.Content css={styles.content}>
          {state.sonicBranding?.restrictedAccess ? (
            <div>
              {userHasAccess && <Content sonicBranding={state.sonicBranding} />}
              {!userHasAccess && <NoAccessScreen />}
            </div>
          ) : (
            <Content sonicBranding={state.sonicBranding} />
          )}
        </Layout.Content>
        <Layout.Footer css={styles.footer} />
      </Layout>
    </div>
  );
}

export default SonicBrandingContent;
