import { css, SerializedStyles } from "@emotion/react";
import theme from "../../../theme";

export default {
  button(buttonTheme: string, isLarge: boolean): SerializedStyles {
    return css`
      background-color: ${buttonTheme === "Primary"
        ? theme.colors.functional.brightOrange
        : theme.colors.background.basalt};
      color: ${theme.colors.white};
      height: 56px;
      width: ${isLarge ? "288px" : "188px"};
      border-radius: 8px;
      padding: 16px 24px 16px 24px;
      font-family: ${theme.fonts.sofiaPro};
      font-size: 1rem;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      margin-top: 2rem;
      border: ${buttonTheme === "Primary"
        ? "none"
        : "1px solid theme.colors.white"};

      :hover,
      :focus {
        opacity: ${buttonTheme === "Primary" ? "0.8" : "1"};
        color: ${buttonTheme === "Primary"
          ? theme.colors.white
          : theme.colors.black};
        background-color: ${buttonTheme === "Primary"
          ? theme.colors.functional.brightOrange
          : "white"};
      }
      :active {
        border: 1px solid
          ${buttonTheme === "Primary"
            ? theme.colors.white
            : theme.colors.functional.brightOrange};
      }
    `;
  },
};
