export const grecaptchaKey = "6LeZoY0fAAAAAILHLwcCZGRZgdfGqseHXIOIY3wA";

export const hubspotFormId = "7d1cf57f-70a7-4608-94a1-a86953d93491";

const heapAnalyticsDomains = [
  "https://cdn.heapanalytics.com",
  "https://heapanalytics.com",
];

const wavAudioBucketUrl =
  "https://songtradr-data.s3-us-west-1.amazonaws.com/UserFiles/OriginalFiles/";

const supportEmail = "select-support@songtradr.com";

/*
  We need to set correct domain for cognito cookie storage to work correctly. 
*/
const generateDevEnv = () => {
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    return "localhost";
  }

  return "songtradr.dev";
};

const generateDataSet = () => {
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1" ||
    window.location.hostname === "assets.portal.dev.songtradr.dev" ||
    window.location.hostname === "assets.portal.staging-2.songtradr.dev"
  ) {
    return "staging";
  }

  return "production";
};

const getEnvironment = () => {
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    return "development";
  }
  if (
    window.location.hostname === "assets.portal.dev.songtradr.dev" ||
    window.location.hostname === "assets.portal.staging-2.songtradr.dev"
  ) {
    return "staging";
  }

  return "production";
};

const removeFromStart = (value?: string, valueToRemove?: string) => {
  if (!value) {
    return "";
  }

  return valueToRemove &&
    valueToRemove.length > 0 &&
    value.slice(0, valueToRemove.length) === valueToRemove
    ? value.slice(valueToRemove.length)
    : value;
};

export const buildEnvUrl = (
  baseSiteSubdomain?: string,
  subdomainPrefix?: string,
  baseUrl?: string
) => {
  if (baseUrl) {
    return baseUrl;
  }

  const siteDomain = removeFromStart(
    window.location.host,
    `${baseSiteSubdomain}.`
  );

  return subdomainPrefix
    ? `${window.location.protocol}//${subdomainPrefix}.${siteDomain}`
    : `${window.location.protocol}//${siteDomain}`;
};

const config = {
  production: {
    production: true,
    sanityProjectID: "cy09zubt",
    sanityDataset: generateDataSet(),
    analytics: {
      heapAppID: "320606725",
      heapDomains: heapAnalyticsDomains,
    },
    assetsUrl: "https://assets.songtradr.net",
    baseSiteSubdomain: "assets",
    auth0: {
      domain: "identity.songtradr.com",
      clientId: "jvAfEIhhdYGPMIjfZXPt43YoHm6p3qxe",
      audience: "songtradr-client-api",
      appName: "portal-spa",
      dbConnection: "portal-app-connection",
    },
    cognito: {
      userPoolId: "us-east-1_ohzpKwsbF",
      clientId: "3jv2k529skmgpgod3ve5v405v9",
      domain: "songtradr.com",
    },
    organisationSubscriptionsService: {
      url: "https://api.songtradr.net/org",
    },
    songCollectionService: {
      url: "https://api.songtradr.net/collections",
    },
    projectManagementService: {
      url: "https://api.songtradr.net/project-management",
    },
    searchService: {
      url: "https://search.api.prod1.songtradr.net",
    },
    songService: {
      url: "https://api.songtradr.net/music/songs/",
    },
    songCDNService: {
      url: "https://api-cdn.songtradr.net",
    },
    musicInsightsProxy: {
      url: "https://api.songtradr.net/song-insights-proxy",
    },
    userInteractionService: {
      url: "https://api.songtradr.net/user-interactions",
    },
    userMetaDataService: {
      url: "https://api.songtradr.net/user-metadata/v2",
    },
    marketplaceService: {
      url: "https://wapi.songtradr.com/web/v3/public",
    },
    player: {
      subdomainPrefix: "player",
    },
    playerAPI: {
      url: "https://player-api.songtradr.com/enterprise",
    },
    enterprise: {
      subdomainPrefix: "",
    },
    support: {
      url: "https://studio-help.songtradr.com",
    },
    assetsSpa: {
      subdomainPrefix: "assets",
    },
    oldPlayerOrgIds: [
      "62c2b97fe714848a99d7bc28", // Zalando
      "62d704a9b1166976d1123f2f", // Kaufland
      "62cd3dae4f230fe5a2fdb9ff", // Heineken international
      "61e8103dd77497e8c2b67c2a", // Sisley Paris
      "6185435ea330c24a5e35cc6c", // L'Oreal Indonesia
    ],
    externalResources: {
      // TODO: Placeholder URLs - these need replaced
      termsOfService: "https://www.songtradr.com/legals/termsofuse",
      registrationLearnMore:
        "https://support.songtradr.com/hc/en-us/articles/115005436448-How-do-I-sign-up-",
    },
    dataDogToken: "pub4b37dc80da1a08d288af1ce3daef9fdb",
    // TODO: This may need to be changed depending on final prod url e.g. "/projects" if base url is "www.vinyl.com/projects"
    basePathname: "/",
    wavAudioBucketUrl,
    webHookUrl:
      "https://webhook-events.api.prod1.songtradr.net/webhooks/events",
    selectHub: {
      doveOrgId: "615b2c2a7e654b8529266946",
      unileverOrgId: "615b2a016f7a1b156e7a3b95",
      sisleyOrgId: "61e8103dd77497e8c2b67c2a",
      hogarthOrgId: "62474a158457d01a0785b0a2",
      nespressoOrgId: "6274e3cbad5b47610d0d6ad3",
      reckittOrgId: "62bddf5be714848a99d7ab1f",
      niveaOrgId: "62c2b5940e378a31e2c57a0d",
      zalandoOrgId: "62c2b97fe714848a99d7bc28",
      kauflandOrgId: "62d704a9b1166976d1123f2f",
      heinekenOrgId: "62cd3dae4f230fe5a2fdb9ff",
    },
    supportEmail,
    stripePublishableKey:
      "pk_live_51J8NoWL3ht73cDqLCS3hgY25MUB81LB8y34af0dPqdJEKeh0VODFtFocLdjNB4Nhnfe9k41W0TrF3El6DTzDu3mD00eGziOMUL",
    products: {
      starter: {
        yearly: "price_1JUr1gL3ht73cDqLEzk6ZjrO",
        monthly: "price_1JUr1XL3ht73cDqLaLVoagkg",
      },
    },
    featureToggles: {
      similarSearch: false,
      priceEstimator: ["62474a158457d01a0785b0a2"],
      showComingSoon: false,
    },
    videoSubscriptionCode: "VMh5uA6u8fqx",
    defaultProjectsSupportEmail:
      "declan.lee+enterprise-musicrequest@songtradr.com; nick.woollard+enterprise-musicrequest@songtradr.com",
    s3CloudfrontDistributionHosts: {
      s3Host: "prod1-project-attachments-bucket.s3.amazonaws.com",
      cloudfrontHost: "project-attachments.prod1.songtradr.dev",
    },
  },
  staging: {
    staging: true,
    sanityProjectID: "cy09zubt",
    sanityDataset: generateDataSet(),
    analytics: {
      heapAppID: "3057716300",
      heapDomains: heapAnalyticsDomains,
    },
    assetsUrl: "https://assets.staging-2.songtradr.dev",
    baseSiteSubdomain: "assets",
    auth0: {
      domain: "identity.staging-2.songtradr.dev",
      clientId: "cyDnUPmBrfHfgE0bOgUj3LHcRmnKWzE8",
      audience: "songtradr-client-api",
      appName: "portal-spa",
      dbConnection: "portal-app-connection",
    },
    cognito: {
      userPoolId: "us-east-1_WFhdIb0np",
      clientId: "6dh8lcq3hvdup914juf4hg0oo1",
      domain: "songtradr.dev",
    },
    recaptcha: {
      // TODO: temporarily using development key
      key: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
    },
    organisationSubscriptionsService: {
      url: "https://api.staging-2.songtradr.dev/org",
    },
    projectManagementService: {
      url: "https://api.staging-2.songtradr.dev/project-management",
    },
    songService: {
      url: "https://api.staging-2.songtradr.dev/music/songs/",
    },
    songCollectionService: {
      url: "https://api.staging-2.songtradr.dev/collections",
    },
    searchService: {
      url: "https://search.api.staging-2.songtradr.dev",
    },
    songCDNService: {
      url: "https://api-cdn.staging-2.songtradr.dev",
    },
    userInteractionService: {
      url: "https://api.staging-2.songtradr.dev/user-interactions",
    },
    userMetaDataService: {
      url: "https://api.staging-2.songtradr.dev/user-metadata/v2",
    },
    musicInsightsProxy: {
      url: "https://api.staging-2.songtradr.dev/song-insights-proxy",
    },
    marketplaceService: {
      url: "https://wapi.songtradr.com/web/v3/public",
    },
    player: {
      subdomainPrefix: "player",
    },
    playerAPI: {
      url: "https://staging-player-api.songtradr.dev/enterprise",
    },
    enterprise: {
      subdomainPrefix: "",
    },
    assetsSpa: {
      subdomainPrefix: "assets",
    },
    support: {
      url: "https://studio-help.songtradr.com",
    },
    oldPlayerOrgIds: [
      "633c38b62f78748dabd8e0ea", // justine.bateman+qa-old-player-enterprise-admin@songtradr.com
    ],
    externalResources: {
      // TODO: Placeholder URLs - these need replaced
      termsOfService: "https://www.songtradr.com/legals/termsofuse",
      registrationLearnMore:
        "https://support.songtradr.com/hc/en-us/articles/115005436448-How-do-I-sign-up-",
    },
    dataDogToken: "pub4b37dc80da1a08d288af1ce3daef9fdb",
    basePathname: "/",
    wavAudioBucketUrl,
    webHookUrl:
      "https://webhook-events.api.staging-2.songtradr.dev/webhooks/events",
    selectHub: {
      doveOrgId: "615d9da59c698208c8704504",
      unileverOrgId: "61951d7ee3408b311f8ab254",
      sisleyOrgId: "62307197ff0659644603ee04",
      hogarthOrgId: "624b1aff4159e8f626979868",
      nespressoOrgId: "6278ec28db20a06f146ffa95",
      reckittOrgId: "62bddf5be714848a99d7ab1f",
      niveaOrgId: "TODO",
      zalandoOrgId: "TODO",
      kauflandOrgId: "TODO",
      heinekenOrgId: "TODO",
    },
    supportEmail,
    stripePublishableKey:
      "pk_test_51J8NFzJ8IyZFlraVIGwmeTtyWUGnYl6DwExN8VH8ADGIGvkaOfFI2HfsGptbFw0tCro0bFkZAEplJHvzvN5QyXLe00V3c1Ltyy",
    products: {
      starter: {
        yearly: "price_1JUqyaJ8IyZFlraVw7NZuwnb",
        monthly: "price_1JUqyQJ8IyZFlraVWafAURrS",
      },
    },
    featureToggles: {
      similarSearch: true,
      priceEstimator: ["6253f49e780aa9b4e8798e54"],
      showComingSoon: false,
    },
    videoSubscriptionCode: "VMh5uA6u8fqx",
    defaultProjectsSupportEmail:
      "justine.bateman+staging-music-request@songtradr.com",
    s3CloudfrontDistributionHosts: {
      s3Host: "staging-project-attachments-bucket.s3.us-east-1.amazonaws.com",
      cloudfrontHost: "project-attachments.staging.songtradr.dev",
    },
  },
  development: {
    development: true,
    sanityProjectID: "cy09zubt",
    sanityDataset: generateDataSet(),
    analytics: {
      heapAppID: "3057716300",
      heapDomains: heapAnalyticsDomains,
    },
    assetsUrl: "https://assets.dev.songtradr.dev",
    baseSiteSubdomain: "assets",
    auth0: {
      domain: "identity.dev.songtradr.dev",
      clientId: "C8McAbjljJBXI84y0otFkMvAOz1Bq0a5",
      audience: "songtradr-client-api",
      appName: "portal-spa",
      dbConnection: "portal-app-connection",
    },
    cognito: {
      userPoolId: "eu-west-1_mTtikScyR",
      clientId: "7p93i8nrk5qo533phdd60u68uq",
      domain: generateDevEnv(),
    },
    recaptcha: {
      key: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
    },
    organisationSubscriptionsService: {
      url: "https://api.dev.songtradr.dev/org",
    },
    projectManagementService: {
      url: process.env.REACT_APP_LOCAL_SERVICE
        ? "http://localhost:5000/project-management"
        : "https://api.dev.songtradr.dev/project-management",
    },
    userInteractionService: {
      url: "https://api.dev.songtradr.dev/user-interactions",
    },
    userMetaDataService: {
      url: process.env.REACT_APP_LOCAL_SERVICE
        ? "http://localhost:5000/user-metadata"
        : "https://api.dev.songtradr.dev/user-metadata/v2",
    },
    searchService: {
      url: "https://search.api.dev.songtradr.dev",
    },
    songService: {
      url: "https://api.dev.songtradr.dev/music/songs/",
    },
    songCDNService: {
      url: "https://api-cdn.dev.songtradr.dev",
    },
    songCollectionService: {
      url: "https://api.dev.songtradr.dev/collections",
    },
    musicInsightsProxy: {
      url: "https://api.dev.songtradr.dev/song-insights-proxy",
    },
    marketplaceService: {
      url: "https://wapi.songtradr.com/web/v3/public",
    },
    player: {
      baseUrl: "https://player.portal.dev.songtradr.dev",
    },
    playerAPI: {
      url: "https://staging-player-api.songtradr.dev/enterprise",
    },
    enterprise: {
      baseUrl: "https://main.portal.dev.songtradr.dev",
    },
    assetsSpa: {
      baseUrl: "https://assets.portal.dev.songtradr.dev",
    },
    support: {
      url: "https://studio-help.songtradr.com",
    },
    oldPlayerOrgIds: [
      "622b687910693a0178d410d1", // justine.bateman+e2e-prtl-enterprise-admin@songtradr.com
      "633c39094234b54d5a8b293f", // justine.bateman+qa-old-player-enterprise-admin@songtradr.com
    ],
    externalResources: {
      // TODO: Placeholder URLs - these need replaced
      termsOfService: "https://www.songtradr.com/legals/termsofuse",
      registrationLearnMore:
        "https://support.songtradr.com/hc/en-us/articles/115005436448-How-do-I-sign-up-",
    },
    dataDogToken: "pub4b37dc80da1a08d288af1ce3daef9fdb",
    basePathname: "/",
    wavAudioBucketUrl,
    webHookUrl: "https://webhook-events.api.dev.songtradr.dev/webhooks/events",
    selectHub: {
      doveOrgId: "61950d83e341070993d3ac8a",
      unileverOrgId: "61409f5faf34af4525c5e750",
      sisleyOrgId: "62306e191d742c4f0e955f83",
      hogarthOrgId: "624b0abcfc1c7077ef72932e",
      nespressoOrgId: "6278ebe8327e6ae651139d03",
      reckittOrgId: "62bddf5be714848a99d7ab1f",
      niveaOrgId: "62d02a3c4577a88961ba1d4c",
      zalandoOrgId: "TODO",
      kauflandOrgId: "TODO",
      heinekenOrgId: "TODO",
    },
    supportEmail,
    stripePublishableKey:
      "pk_test_51J04UZK2dtxtJJU4dR2OYk9nlz5VjEKWmJR6FugUVeJh808U7jMa31t7vxPduuS5GgdFPvqX2rWibU0RGSTDEZPm00QDhXBGpE",
    products: {
      starter: {
        yearly: "price_1JUqAsK2dtxtJJU4fzonKC54",
        monthly: "price_1JUqCqK2dtxtJJU4RqF03lzi",
      },
    },
    featureToggles: {
      similarSearch: true,
      priceEstimator: ["6253f49e780aa9b4e8798e54"],
      showComingSoon: false,
    },
    videoSubscriptionCode: "VMh5uA6u8fqx",
    defaultProjectsSupportEmail:
      "justine.bateman+dev-music-request-one@songtradr.com; justine.bateman+dev-music-request-two@songtradr.com",
    s3CloudfrontDistributionHosts: {
      s3Host: "",
      cloudfrontHost: "",
    },
  },
};

export default config[getEnvironment()] || config.development;
