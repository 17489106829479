import config from "src/config";

export const IS_DEV = (): boolean =>
  window.location.host.includes("dev.songtradr.dev");

export const IS_STAGING = (): boolean =>
  window.location.host.includes("staging-2.songtradr.dev");

export const IS_LOCAL = (): boolean => process.env.NODE_ENV === "development";

export const IS_DEPLOY_PREVIEW = (): boolean =>
  window.location.host.includes("deploy-preview");

export const GQL_URI_BASE = () => {
  return config.playerAPI.url;
};

export const USER_METADATA_SERVICE_BASE_URL = () => {
  if (process.env.NX_USER_METADATA_SERVICE_BASE_URL) {
    return process.env.NX_USER_METADATA_SERVICE_BASE_URL;
  }

  if (IS_LOCAL() || IS_DEV() || IS_DEPLOY_PREVIEW()) {
    return "https://api.dev.songtradr.dev";
  }

  if (IS_STAGING()) {
    return "https://api.staging-2.songtradr.dev";
  }

  return "https://api.songtradr.net";
};

export const COOKIE_DOMAIN = () => {
  if (IS_LOCAL() || IS_DEPLOY_PREVIEW()) {
    return window.location.hostname;
  }

  if (IS_DEV() || IS_STAGING()) {
    return "songtradr.dev";
  }

  return "songtradr.com";
};

export const COGNITO_DATA = () => {
  if (IS_LOCAL() || IS_DEV() || IS_DEPLOY_PREVIEW()) {
    return {
      userPoolId: "eu-west-1_mTtikScyR",
      clientId: "7p93i8nrk5qo533phdd60u68uq",
    };
  }

  if (IS_STAGING()) {
    return {
      userPoolId: "us-east-1_WFhdIb0np",
      clientId: "6dh8lcq3hvdup914juf4hg0oo1",
    };
  }

  return {
    userPoolId: "us-east-1_ohzpKwsbF",
    clientId: "3jv2k529skmgpgod3ve5v405v9",
  };
};

export const ENV = (() => {
  return {
    USER_METADATA_SERVICE_BASE_URL: USER_METADATA_SERVICE_BASE_URL(),
    GQL_URI_BASE: GQL_URI_BASE(),
    COOKIE_DOMAIN: COOKIE_DOMAIN(),
  };
})();

export default ENV;
