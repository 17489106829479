/** @jsxImportSource @emotion/react */
/* eslint-disable no-underscore-dangle */
import React from "react";
import Lottie from "react-lottie-player";
import config from "src/config";
import imageUrlBuilder from "@sanity/image-url";
import { ISonicBranding, IRow } from "../../interfaces";
import Title from "../title";
import NoContentScreen from "../no-content";
import VideoComponent from "../video";
import Columns from "../columns";
import Image from "../image";
import backgroundLinesAnimation from "../../assets/animations/bars_bg.json";
import { BackButton } from "../back-button";
import styles from "./styles";

const sanityClient = require("@sanity/client");

const PROJECT_ID = config.sanityProjectID;
const DATASET = config.sanityDataset;

const client = sanityClient({
  projectId: PROJECT_ID,
  dataset: DATASET,
  apiVersion: new Date().toISOString().split("T")[0],
  useCdn: true,
});

interface IContentProps {
  sonicBranding: ISonicBranding | null;
}

const Content = ({ sonicBranding }: IContentProps) => {
  const builder = imageUrlBuilder(client);

  function urlFor(source: string) {
    return builder.image(source).url();
  }

  const getUrlFromId = (ref: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_file, id, extension] = ref.split("-");
    return `https://cdn.sanity.io/files/${PROJECT_ID}/${DATASET}/${id}.${extension}`;
  };

  if (sonicBranding) {
    const {
      logo,
      titleItem,
      image,
      columns,
      row,
      video,
      sonicLogoWavUrl,
      showVSTOffering,
      vstDescription,
      watermarkingDescription,
      watermarkingWavFiles,
    } = sonicBranding;
    return (
      <>
        <div css={styles.logoContainer}>
          <div css={styles.headerBackBtn}>
            <BackButton />
          </div>
          {sonicBranding && logo && (
            <div css={styles.orgLogoContainer}>
              <img
                src={urlFor(logo.asset._ref)}
                alt="org logo"
                css={styles.orgLogo}
              />
            </div>
          )}
          <div css={styles.animationBg}>
            <Lottie
              animationData={backgroundLinesAnimation}
              style={{ width: 1000, height: 175 }}
              loop
              play
            />
          </div>
        </div>

        {titleItem ? (
          titleItem.map((textSection) => (
            <Title title={textSection.title} isLarge={textSection.isLarge} />
          ))
        ) : (
          <div />
        )}

        {image
          ? image.map((item) => (
              <div key={item.image.asset._ref}>
                <Image src={urlFor(item.image.asset._ref)} width={item.width} />
              </div>
            ))
          : null}

        {columns ? <Columns columns={columns} /> : null}

        {row
          ? row.map((itemRow: IRow) => (
              <Columns
                columns={itemRow.columns}
                bottomMargin={itemRow.bottomMargin}
              />
            ))
          : null}

        {video
          ? video.map((itemVideo) => (
              <VideoComponent
                width={itemVideo.width}
                url={
                  itemVideo.file
                    ? getUrlFromId(itemVideo.file.asset._ref)
                    : itemVideo.url
                }
              />
            ))
          : null}

        {/* {state.sonicBranding.files
          ? state.sonicBranding.files.map((file) => (
              <FileDownload
                file={file}
                width={file.width}
                url={
                  file.fileUpload
                    ? getDownloadUrlFromId(file.fileUpload.asset._ref)
                    : file.url
                }
              />
            ))
          : null} */}

        {sonicLogoWavUrl ? (
          /* eslint-disable-next-line jsx-a11y/media-has-caption */
          <audio controls>
            <source src={sonicLogoWavUrl} />
          </audio>
        ) : (
          <div />
        )}

        {showVSTOffering && (
          <>
            <h1>VST</h1>
            <p>{vstDescription}</p>
          </>
        )}

        {showVSTOffering && (
          <>
            <h1>Watermarking</h1>
            <p>{watermarkingDescription}</p>
            {watermarkingWavFiles?.map((watermarkingWavFilesItem) => (
              <li key={watermarkingWavFilesItem._key}>
                {watermarkingWavFilesItem.trackName} (
                {watermarkingWavFilesItem.trackUrl})
              </li>
            ))}
          </>
        )}
      </>
    );
  }
  return <NoContentScreen />;
};
export default Content;
