import saveAs from "file-saver";
import downloadFile from "src/api/attachments/download-file";
import { errorToast, successToast } from "src/components/toast-notification";
import { IFileInfo } from "..";

const handleDownloadClick = async (url?: string, fileInfo?: IFileInfo) => {
  if (!url || !fileInfo) {
    return;
  }
  const fileToSave: Blob = await downloadFile(url, fileInfo.type);
  if (fileToSave) {
    const { fileName, type } = fileInfo;
    const fileNameWithExtension = `${fileName}.${type}`;
    successToast({ message: "File downloading to your file storage" });
    saveAs(fileToSave, fileNameWithExtension);
  } else {
    errorToast({
      message:
        "There was a problem downloading this track, please refresh the page and try again.",
    });
  }
};

export default handleDownloadClick;
