import config from "src/config";
import { buildEnvUrl } from "src/config/config";

interface ISubscription {
  name: string;
  path: string;
}

export const Subscriptions = ((): { [id: string]: ISubscription } => {
  // Setup Runtime Config Values
  config.player.url = buildEnvUrl(
    config.baseSiteSubdomain,
    config.player.subdomainPrefix,
    config.player.baseUrl
  );
  config.enterprise.url = buildEnvUrl(
    config.baseSiteSubdomain,
    config.enterprise.subdomainPrefix,
    config.enterprise.baseUrl
  );
  config.assetsSpa.url = buildEnvUrl(
    config.baseSiteSubdomain,
    config.assetsSpa.subdomainPrefix,
    config.assetsSpa.baseUrl
  );

  return {
    Projects: {
      name: "Projects",
      path: `${config.enterprise.url}/commerciallicenses`,
    },
    Library: {
      name: "Library",
      path: `${config.enterprise.url}/library`,
    },
    ReadOnlyLibrary: {
      name: "Library (read-only)",
      path: `${config.enterprise.url}/library`,
    },
    Insights: {
      name: "Insights",
      path: `${config.enterprise.url}/musicinsights`,
    },
    Team: {
      name: "Manage Team",
      path: `${config.enterprise.url}/team`,
    },
    Home: {
      name: "Home",
      path: `${config.enterprise.url}/home`,
    },
    Profile: {
      name: "Profile",
      path: `${config.enterprise.url}/profile`,
    },
    MyContent: {
      name: "My Content",
      path: `${config.enterprise.url}/assets`,
    },
    Validate: {
      name: "Measurement",
      path: `${config.enterprise.url}/measurement`,
    },
    OrganizationDashboard: {
      name: "Organization Dashboard",
      path: `${config.enterprise.url}/admin/organization-dashboard`,
    },
  };
})();

export default Subscriptions;
