/** @jsxImportSource @emotion/react */
import React from "react";
import { Slider } from "antd";
import ControlButton from "src/components/control-buttons/base-button";
import VolumeIcon from "src/assets/icons/volume-icon";
import styles from "../../styles";

interface IProgressBarProps {
  currentVolume: number;
  setCurrentVolume: (volume: number) => void;
  disabled?: boolean;
}

const VolumeControl = ({
  currentVolume,
  setCurrentVolume,
  disabled = false,
}: IProgressBarProps) => {
  const handleVolumeChange = (volume: number) => {
    setCurrentVolume(volume);
  };

  return (
    <>
      <ControlButton
        css={styles.volumeButton}
        label={currentVolume > 0 ? "Mute" : "Unmute"}
        onClick={() => {
          if (currentVolume > 0) handleVolumeChange(0);
          else handleVolumeChange(0.3);
        }}
        data-testid="volume-button"
      >
        <VolumeIcon />
      </ControlButton>
      <div css={styles.volumeControl}>
        <Slider
          data-testid="track-volume-control"
          min={0}
          max={1}
          step={0.01}
          value={currentVolume}
          disabled={disabled}
          onChange={(e) => handleVolumeChange(e)}
        />
      </div>
    </>
  );
};

export default VolumeControl;
