import { ReactElement } from "react";

const ArrowLeft = (): ReactElement => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 12 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6065 21.9202C11.997 21.5297 11.997 20.8965 11.6065 20.506L1.70703 10.6065C1.3165 10.216 0.683336 10.216 0.292812 10.6065C-0.0977116 10.997 -0.0977125 11.6302 0.292812 12.0207L10.1923 21.9202C10.5828 22.3108 11.216 22.3108 11.6065 21.9202Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.292812 12.0207C0.683336 12.4113 1.31649 12.4114 1.70701 12.0209L11.6065 2.12141C11.997 1.73089 11.997 1.09772 11.6065 0.7072C11.216 0.316676 10.5828 0.316675 10.1923 0.7072L0.292812 10.6065C-0.0977116 10.997 -0.0977125 11.6302 0.292812 12.0207Z"
        fill="white"
      />
    </svg>
  );
};

export default ArrowLeft;
