import { css, SerializedStyles } from "@emotion/react";

export default {
  videoContainer(width: number): SerializedStyles {
    return css`
      width: ${width}%;
      aspect-ratio: 16/9;
      border: none;
      margin-top: 2rem;
      margin-bottom: 2rem;
    `;
  },
  videoComponent: css`
    width: 100%;
  `,
};
