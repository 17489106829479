/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { IAuthenticatedUser } from "@songtradr/spa-common";
import {
  IEnterpriseUser,
  IGuestUser,
  IUserPermissions,
} from "../Platform/EnterprisePlatform";

export interface ICognitoContext {
  login: (username: string, password: string) => Promise<void | string>;
  logout: () => void;
  loginGuest: (jwt: string) => Promise<void>;
  hasLoaded: boolean;
  pretzelUser?: IEnterpriseUser | IGuestUser | null;
  loadData: (key: string) => string;
  saveData: (key: string, value: string) => void;
  userPermissions: IUserPermissions;
  getUserAccessToken: () => Promise<string | null>;
  organisationId: string;
  setOrganisationId: (id: string) => void;
  isSuperAdmin: boolean;
  profileImage?: string;
  user?: IEnterpriseUser;
  guestUser?: IGuestUser;
}

export const CognitoContext = React.createContext<ICognitoContext>({
  login: (username: string, password: string) => Promise.resolve(),
  logout: () => Promise.resolve(),
  loginGuest: (guid: string) => Promise.resolve(),
  hasLoaded: false,
  pretzelUser: null,
  loadData: (key) => "",
  saveData: (key, value) => null,
  userPermissions: {
    canAccessPortal: false,
    canAccessInsights: false,
    canAccessMyContent: false,
    canAccessRequests: false,
    canAccessValidate: false,
    canAccessTeams: false,
    canAccessProjects: false,
    canAccessMusicSubscription: false,
  },
  getUserAccessToken: () => Promise.resolve(null),
  organisationId: "",
  setOrganisationId: () => Promise.resolve(null),
  isSuperAdmin: false,
  profileImage: undefined,
  user: undefined,
  guestUser: undefined,
});
