/* eslint-disable jsx-a11y/media-has-caption  */
/* eslint-disable jsx-a11y/iframe-has-title */
/** @jsxImportSource @emotion/react */
import axios from "axios";
import React, { ReactElement, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { getAuthHeader, getJsonContentHeader } from "src/api/headers";
import { useAuth } from "src/CognitoAuth/useAuth";
import { errorToast } from "../toast-notification";
import styles from "./styles";
import config from "../../config";

interface IProps {
  url?: string;
  width?: number;
  isProjectAsset?: boolean;
}

const VideoComponent = ({
  url,
  width,
  isProjectAsset,
}: IProps): ReactElement => {
  const { getUserAccessToken, organisationId } = useAuth();
  const [s3AssetUrl, setS3AssetUrl] = useState();

  const getVideoFromAssetUrl = async (assetUrl: string) => {
    const accessToken = await getUserAccessToken();
    if (accessToken) {
      const options = {
        headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
      };
      const data = {
        organisationId,
      };
      const response = await axios
        .post(assetUrl, data, options)
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 403) {
              errorToast({
                message:
                  "Sorry, you do not have permission to play this video. Please check it belongs to this organisation and the 'Share with client' option has been selected.",
              });
            }
          }
        });
      if (!response) {
        return;
      }

      const cloudfrontUrl = response.data.url.replace(
        config.s3CloudfrontDistributionHosts.s3Host,
        config.s3CloudfrontDistributionHosts.cloudfrontHost
      );

      setS3AssetUrl(cloudfrontUrl);
    } else {
      errorToast({ message: "Error, unable to authenticate this user." });
    }
  };

  useEffect(() => {
    if (isProjectAsset && url) {
      getVideoFromAssetUrl(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div css={styles.videoContainer(width || 100)}>
      <ReactPlayer
        controls
        url={s3AssetUrl || url}
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default VideoComponent;
