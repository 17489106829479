import { css, SerializedStyles } from "@emotion/react";
import theme from "src/theme";

export default {
  container(marginBottom: number): SerializedStyles {
    return css`
      display: flex;
      justify-content: space-between;
      margin-bottom: ${marginBottom}px;
    `;
  },
  column(width: number, position?: string): SerializedStyles {
    return css`
      display: flex;
      flex-basis: ${width}%;
      justify-content: ${position};
    `;
  },
  portableText: css`
    margin-top: 16px;
    margin-bottom: 16px;
    font-family: ${theme.fonts.sofiaPro};
    > * {
      color: ${theme.colors.white} !important;
    }
  `,
  downloadAllBtn: css`
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  `,
  button: css`
    background-color: ${theme.colors.functional.brightOrange};
    color: ${theme.colors.white};
    height: 56px;
    width: 188px;
    border-radius: 8px;
    padding: 16px 24px 16px 24px;
    font-family: ${theme.fonts.sofiaPro};
    font-size: 1rem;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 2rem;
    border: none;

    :hover,
    :focus {
      opacity: 0.8;
      color: ${theme.colors.white};
      background-color: ${theme.colors.functional.brightOrange};
    }
    :active {
      border: 1px solid ${theme.colors.white};
    }
  `,
  errorLabel: css`
    color: ${theme.colors.white};
  `,
};
