import { css, SerializedStyles } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import { play, pause } from "../../../assets/icons/svg-icons";
import theme from "../../../theme";

export default {
  container(width: number): SerializedStyles {
    return css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: ${width}%;
    `;
  },
  player: css`
    width: 100%;
    display: flex;
    margin: 0 !important;
    align-items: center;

    .baseBtnTooltip {
      .ant-tooltip-inner { 
     background: ${theme.colors.white} !important;
     color: ${theme.colors.black} !important;
     font-family: ${theme.fonts.sofiaPro};
     position: relative;
     display: flex;
     align-items: center;
     top: 0.2rem !important;
     color: ${theme.colors.black}
     font-size: 0.75rem;
     font-weight: 700;
     min-height: 26px !important;
     border-radius: 4px;
     height: 26px;
   }
   .ant-tooltip-arrow {
     z-index: 2;
     top: -1px
   }

   .ant-tooltip-arrow-content {
     z-index: 2;
     width: 0.75rem;
     height: 0.75rem;
     margin-top: 0.9rem;
   }
    }
  `,
  playButtonContainer: css`
    width: 64px;
    margin-right: 0 !important;
  `,
  playButton: css`
    border-radius: 50%;
    background-color: ${theme.colors.white};
    height: 64px;
    width: 64px;
  `,
  fileNameContainer: css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 15%;
    margin-left: 16px;
    margin-right: 16px !important;
  `,
  fileName: css`
    color: ${theme.colors.white} !important;
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 400;
  `,
  waveform: css`
    display: none;

    @media ${queries.medium} {
      display: block;
      width: 15%;
      margin-top: 0.438rem;
      margin-right: 0.625rem;
    }

    @media ${queries.large} {
      width: 15%;
    }
  `,
  tabletDownloadButton: css`
    background-color: transparent;
    border: none;
    &:hover {
      cursor: pointer;
      svg path {
        fill: ${theme.colors.secondary.brightOrange};
      }
    }
  `,
  downloadButtonContainer: css`
    width: 48px;

    button {
      width: 48px;
    }
  `,
  playing: css`
    background-image: url(${pause});
  `,
  paused: css`
    background-image: url(${play});
  `,
  control: css`
    padding: 0;
    height: 64px;
    width: 64px;

    @media ${queries.small} {
      height: 48px;
      width: 48px;
    }

    :hover circle {
      display: none;
    }
  `,
  trackBar(isDisabled: boolean): SerializedStyles {
    return css`
      width: 100%;
      display: grid;
      align-items: center;
      margin-right: 0 !important;
      opacity: ${isDisabled ? 0.5 : 1};

      .ant-slider-handle {
        display: none;
      }

      .ant-slider-track {
        background-color: ${theme.colors.secondary.brightOrange} !important;
      }
      .ant-slider:hover() .ant-slider-track {
        background-color: ${theme.colors.secondary.brightOrange} !important;
      }

      .ant-slider-rail {
        background-color: white !important;
      }
    `;
  },
  currentTime: css`
    color: ${theme.colors.white};
    font-size: 0.875rem;
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 400;
    display: flex;
    align-items: center;
    margin-right: 16px !important;
  `,
  duration: css`
    color: ${theme.colors.white};
    font-size: 0.875rem;
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 400;
    display: flex;
    align-items: center;
    margin-left: 16px !important;
    margin-right: 0 !important;
  `,
  volumeControl: css`
    min-width: 64px;
    display: grid;
    align-items: center;

    .ant-slider-handle {
      display: none;
    }

    .ant-slider-track {
      background-color: ${theme.colors.secondary.brightOrange} !important;
    }
    .ant-slider:hover() .ant-slider-track {
      background-color: ${theme.colors.secondary.brightOrange};
    }

    .ant-slider-rail {
      background-color: white !important;
    }
  `,
  volumeButton: css`
    display: block;
    align-items: center;
    width: 32px;
    height: 48px;
    margin-top: 2px;
    margin-left: 16px;
    padding: 0;

    &:hover {
      svg path {
        fill: ${theme.colors.secondary.brightOrange};
      }
    }
  `,
};
