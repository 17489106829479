/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/media-has-caption  */
/** @jsxImportSource @emotion/react */
import React, { ReactElement, useState } from "react";
import { MapContainer, TileLayer, Popup, Marker } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import styles from "./styles";
import MapMarker from "../../assets/icons/location_point.png";
import config from "../../config";
import { ISoundMap } from "../../interfaces";

const minLat = 1.1276;
const maxLat = 51.5072;
const minLong = 3.6173;
const maxLong = 13.6917;

const distanceLat = maxLat - minLat;
const bufferLat = distanceLat * 0.05;
const distanceLong = maxLong - minLong;
const bufferLong = distanceLong * 0.15;

const newicon = L.icon({
  iconUrl: MapMarker,
  iconSize: [14, 14],
});

interface IProps {
  mapData: ISoundMap;
}

const Map = ({ mapData }: IProps): ReactElement => {
  const PROJECT_ID = config.sanityProjectID;
  const DATASET = config.sanityDataset;

  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const audioControl = () => {
    const audioPlayer = document.getElementById(
      "player"
    ) as HTMLAudioElement | null;
    if (audioPlayer !== null && !isPlaying) {
      audioPlayer.play();
      setIsPlaying(true);
    }
    if (audioPlayer !== null && isPlaying) {
      audioPlayer.pause();
      setIsPlaying(false);
    }
  };

  const getUrlFromId = (ref: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_file, id, extension] = ref.split("-");
    return `https://cdn.sanity.io/files/${PROJECT_ID}/${DATASET}/${id}.${extension}`;
  };

  return (
    <div css={styles.mapWrapper} key={mapData._key}>
      <MapContainer
        scrollWheelZoom={false}
        zoomControl={false}
        doubleClickZoom={false}
        dragging={false}
        keyboard={false}
        style={{ height: "100%", width: "100%" }}
        center={[40, -1]}
        zoom={2}
        bounds={[
          [minLat - bufferLat, minLong - bufferLong],
          [maxLat + bufferLat, maxLong + bufferLong],
        ]}
      >
        <TileLayer
          attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
          url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_nolabels/{z}/{x}/{y}.png"
        />
        {mapData.mapLocationItem.map((item) => {
          return (
            <Marker
              position={[item.location.lat, item.location.lng]}
              icon={newicon}
              key={item._key}
            >
              <Popup css={styles.popup} className="custom-popup">
                <div css={styles.popupContent}>
                  <audio css={styles.audioPlayer} id="player">
                    <source src={getUrlFromId(item.audioUpload.asset._ref)} />
                  </audio>
                  <button
                    css={styles.playBtn(isPlaying)}
                    onClick={() => audioControl()}
                    type="button"
                  />
                  <div css={styles.placeName}>{item.locationName}</div>
                </div>
              </Popup>
            </Marker>
          );
        })}
      </MapContainer>
    </div>
  );
};

export default Map;
