/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/iframe-has-title */
/** @jsxImportSource @emotion/react */
import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";
import React, { ReactElement } from "react";
import { Button } from "antd";
import { PortableText } from "@portabletext/react";
import config from "../../config";
import {
  IIFrame,
  ITextSection,
  IVideo,
  IImage,
  IButton,
  IFileUpload,
  ISoundMap,
  IMusicPlayer,
  IPlaylistTrack,
  IUploadedFile,
} from "../../interfaces";
import IFrame from "../i-frame";
import TextSection from "../text-section";
import VideoComponent from "../video";
import Image from "../image";
import styles from "./styles";
import CustomButton from "../button";
import FileDownload from "../file-download";
import Map from "../map";
import MusicPlayer from "../music-player";
import handleDownloadClick from "../music-player/util";
import musicPlayerStyles from "../../styles";

interface IBlockText {
  _key: string;
  _type: string;
  children: IText[];
  markDefs: string[];
  style: string;
}

interface IText {
  _key: string;
  _type: string;
  marks: string[];
  text: string;
}

interface IProps {
  columns?: Array<
    | Array<IIFrame>
    | Array<IImage>
    | Array<IVideo>
    | Array<IButton>
    | Array<ITextSection>
    | Array<ISoundMap>
    | Array<IMusicPlayer>
    | Array<IBlockText>
    | any
  >;
  bottomMargin?: number;
}

export interface IFileInfo {
  fileName: string;
  type: string;
}

const Columns = ({ columns, bottomMargin }: IProps): ReactElement => {
  const PROJECT_ID = config.sanityProjectID;
  const DATASET = config.sanityDataset;
  const client = sanityClient({
    projectId: PROJECT_ID,
    dataset: DATASET,
    apiVersion: new Date().toISOString().split("T")[0],
    useCdn: true,
  });

  const builder = imageUrlBuilder(client);
  function urlFor(source: string) {
    return builder.image(source).url();
  }

  const getUrlFromId = (ref: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_file, id, extension] = ref.split("-");
    return `https://cdn.sanity.io/files/${PROJECT_ID}/${DATASET}/${id}.${extension}`;
  };

  const getDownloadUrlFromId = (ref: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_file, id, extension] = ref.split("-");
    return `https://cdn.sanity.io/files/${PROJECT_ID}/${DATASET}/${id}.${extension}?dl=${id}.${extension}`;
  };

  const handleDownloadAll = (tracks: IPlaylistTrack[] | IUploadedFile[]) => {
    tracks.map((track) => {
      const url = track.fileUpload
        ? getDownloadUrlFromId(track.fileUpload.asset._ref)
        : track.url;
      const isUploadedUrl = url.startsWith("https://cdn.sanity.io");
      const fileType = isUploadedUrl
        ? url.substring(url.lastIndexOf(".") + 1)
        : "";
      const fileInfo: IFileInfo = {
        fileName: track.title || "Untitled",
        type: fileType,
      };
      if (track.isDownloadable) {
        handleDownloadClick(url, fileInfo);
        return 1;
      }
      return null;
    });
  };

  const components = {
    marks: {
      link: ({ value, children }: any) => {
        const { blank, href } = value;
        return blank ? (
          <a href={href} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        ) : (
          <a href={href}>{children}</a>
        );
      },
    },
  };

  const hasMultipleColumns = (columns ?? [])?.length > 1;

  return (
    <div css={styles.container(bottomMargin || 0)}>
      {columns !== undefined ? (
        columns?.map((column) => {
          if ("customTextSection" in column) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            const section = column["customTextSection"][0];
            if (section !== undefined) {
              return (
                <div css={styles.column(section.width || 100)}>
                  <div css={styles.portableText}>
                    <PortableText
                      value={section.portableText}
                      components={components}
                    />
                  </div>
                </div>
              );
            }
          }

          if ("textSection" in column) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            const section = column["textSection"][0] as ITextSection;
            if (section !== undefined) {
              return (
                <div css={styles.column(section.width || 100)}>
                  <TextSection
                    title={section.title}
                    description={section.description}
                    width={100}
                  />
                </div>
              );
            }
          }

          if ("image" in column) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            const section = column["image"][0] as IImage;
            if (section !== undefined) {
              return (
                <div
                  key={section.image.asset._ref}
                  css={styles.column(section.width)}
                >
                  <Image src={urlFor(section.image.asset._ref)} width={100} />
                </div>
              );
            }
          }

          if ("files" in column) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            const section = column["files"][0] as IFileUpload;
            if (section !== undefined) {
              const fileList = section.fileUploadItem;
              return (
                <div
                  css={musicPlayerStyles.fileUploaderContainer(
                    section.width || 100
                  )}
                >
                  <div className="fileUploader">
                    {section.fileUploadItem !== undefined ? (
                      section.fileUploadItem.map((file) => {
                        if (file.fileUpload || file.url) {
                          return (
                            <div
                              className="track"
                              key={
                                file.fileUpload
                                  ? getDownloadUrlFromId(
                                      file.fileUpload.asset._ref
                                    )
                                  : file.url
                              }
                            >
                              <FileDownload
                                url={
                                  file.fileUpload
                                    ? getDownloadUrlFromId(
                                        file.fileUpload.asset._ref
                                      )
                                    : file.url
                                }
                                file={file}
                              />
                            </div>
                          );
                        }
                        return (
                          <div css={styles.errorLabel}>
                            Error: File Uploader - Upload a file or include a
                            projects URL
                          </div>
                        );
                      })
                    ) : (
                      <div />
                    )}
                  </div>
                  {section.showDownloadAllBtn ? (
                    <div css={styles.downloadAllBtn}>
                      <Button
                        css={styles.button}
                        onClick={() => {
                          handleDownloadAll(fileList);
                        }}
                      >
                        Download all
                      </Button>
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              );
            }
          }

          if ("video" in column) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            const section = column["video"][0] as IVideo;
            if (section !== undefined) {
              return (
                <div key={section.url} css={styles.column(section.width)}>
                  <VideoComponent
                    width={100}
                    url={
                      section.file
                        ? getUrlFromId(section.file.asset._ref)
                        : section.url
                    }
                    isProjectAsset={!section.file}
                  />
                </div>
              );
            }
          }

          if ("iFrame" in column) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            const section = column["iFrame"][0] as IIFrame;
            if (section !== undefined) {
              return (
                <div
                  key={section.url}
                  css={styles.column(section.width || 100)}
                >
                  <IFrame
                    url={section.url}
                    width={100}
                    height={section.height}
                  />
                </div>
              );
            }
          }

          if ("image" in column) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            const section = column["image"][0] as IImage;
            if (section !== undefined) {
              return (
                <div
                  key={section.image.asset._ref}
                  css={styles.column(section.width || 100)}
                >
                  <Image src={urlFor(section.image.asset._ref)} width={100} />
                </div>
              );
            }
          }

          if ("customButton" in column) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            const section = column["customButton"][0] as IButton;
            if (section !== undefined) {
              return (
                <div
                  key={section._key}
                  css={styles.column(100, section.position)}
                >
                  <CustomButton
                    buttonText={section.buttonText}
                    linkTo={section.linkTo}
                    openInNewWindow={section.openInNewWindow}
                    isLarge={section.isLarge}
                    buttonTheme={section.isPrimary}
                  />
                </div>
              );
            }
          }

          if ("SoundMap" in column) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            const section = column["SoundMap"][0] as ISoundMap;
            if (section !== undefined) {
              return (
                <div key={section._key} css={styles.column(100)}>
                  <Map mapData={section} />
                </div>
              );
            }
          }

          if ("MusicPlayer" in column) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            const section = column["MusicPlayer"][0] as IMusicPlayer;
            const trackList = section.musicPlayerTrackItem;

            return (
              <div css={musicPlayerStyles.trackContainer(section.width || 100)}>
                <div className="track">
                  {section.musicPlayerTrackItem !== undefined ? (
                    section.musicPlayerTrackItem.map((track) => {
                      if (track.fileUpload || track.url) {
                        return (
                          <div
                            className="track"
                            key={
                              track.fileUpload
                                ? getDownloadUrlFromId(
                                    track.fileUpload.asset._ref
                                  )
                                : track.url
                            }
                          >
                            <MusicPlayer
                              url={
                                track.fileUpload
                                  ? getDownloadUrlFromId(
                                      track.fileUpload.asset._ref
                                    )
                                  : track.url
                              }
                              handleDownloadClick={
                                track.isDownloadable
                                  ? handleDownloadClick
                                  : undefined
                              }
                              fileName={track.title || "Untitled"}
                              columnWidth={100}
                              isDownloadable={track.isDownloadable}
                              multiColumns={hasMultipleColumns}
                            />
                          </div>
                        );
                      }
                      return (
                        <div css={styles.errorLabel}>
                          Error: Music Player - Upload a track or include a
                          projects URL
                        </div>
                      );
                    })
                  ) : (
                    <div />
                  )}
                </div>
                {section.showDownloadAllBtn ? (
                  <div css={styles.downloadAllBtn}>
                    <Button
                      css={styles.button}
                      onClick={() => {
                        handleDownloadAll(trackList);
                      }}
                    >
                      Download all
                    </Button>
                  </div>
                ) : (
                  <div />
                )}
              </div>
            );
          }
          return <></>;
        })
      ) : (
        <div />
      )}
    </div>
  );
};

export default Columns;
