import { css, SerializedStyles } from "@emotion/react";
import theme from "../../../theme";

export default {
  textSection: css`
    padding-top: 24px;
    padding-bottom: 24px;
    background-color: #141414;
  `,
  title(width: number): SerializedStyles {
    return css`
      font-family: ${theme.fonts.sofiaPro};
      color: #fff;
      font-size: 2rem;
      font-weight: 250;
      line-height: 2.5rem;
      letter-spacing: -0.02em;
      margin-bottom: 1rem;
      text-align: left;
      width: ${width}%;
    `;
  },
  description(width: number): SerializedStyles {
    return css`
      font-family: ${theme.fonts.sofiaPro};
      color: #f3f3f3;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      letter-spacing: 0;
      text-align: left;
      width: ${width}%;
    `;
  },
};
