import { ReactElement } from "react";

const Loading = (): ReactElement => {
  return (
    <svg
      width="36"
      height="43"
      viewBox="0 0 36 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_438_1148"
        maskUnits="userSpaceOnUse"
        x="17"
        y="0"
        width="19"
        height="19"
      >
        <rect
          x="36"
          y="19"
          width="19"
          height="19"
          transform="rotate(-180 36 19)"
          fill="#C4C4C4"
        />
      </mask>
      <g mask="url(#mask0_438_1148)">
        <path
          d="M323.879 17.2012L19.5905 17.2012C19.3939 17.2009 19.2053 17.1227 19.0662 16.9837C18.927 16.8448 18.8485 16.6563 18.8479 16.4597V0.833886C18.8479 0.612744 18.76 0.400659 18.6037 0.244287C18.4473 0.0879161 18.2352 6.75321e-05 18.0141 6.75321e-05C17.793 6.75321e-05 17.5809 0.0879161 17.4245 0.244287C17.2681 0.400659 17.1802 0.612744 17.1802 0.833886V16.4597C17.1811 17.0985 17.4354 17.711 17.8873 18.1626C18.3391 18.6143 18.9517 18.8682 19.5905 18.8688L323.879 18.8688V17.2012ZM-287.856 25.9841L16.432 25.9841C16.6288 25.9844 16.8174 26.0628 16.9565 26.202C17.0955 26.3412 17.1736 26.53 17.1736 26.7267V42.3481C17.1736 42.5692 17.2614 42.7813 17.4178 42.9377C17.5742 43.094 17.7863 43.1819 18.0075 43.1819C18.2286 43.1819 18.4407 43.094 18.597 42.9377C18.7534 42.7813 18.8413 42.5692 18.8413 42.3481V26.7245C18.8407 26.0856 18.5867 25.4731 18.135 25.0212C17.6834 24.5694 17.0709 24.3151 16.432 24.3142L-287.856 24.3142V25.9841Z"
          fill="#C4C4C4"
        />
      </g>
      <mask
        id="mask1_438_1148"
        maskUnits="userSpaceOnUse"
        x="0"
        y="23"
        width="19"
        height="20"
      >
        <rect y="23.9399" width="19" height="19" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask1_438_1148)">
        <path
          d="M-287.879 25.7387H16.4095C16.6061 25.739 16.7947 25.8172 16.9338 25.9562C17.073 26.0952 17.1515 26.2836 17.1521 26.4803V42.1061C17.1521 42.3272 17.24 42.5393 17.3963 42.6957C17.5527 42.852 17.7648 42.9399 17.9859 42.9399C18.207 42.9399 18.4191 42.852 18.5755 42.6957C18.7319 42.5393 18.8198 42.3272 18.8198 42.1061V26.4803C18.8189 25.8414 18.5646 25.229 18.1127 24.7773C17.6609 24.3257 17.0483 24.0717 16.4095 24.0711H-287.879V25.7387ZM323.856 16.9559H19.568C19.3712 16.9556 19.1826 16.8772 19.0435 16.7379C18.9045 16.5987 18.8264 16.41 18.8264 16.2132V0.591876C18.8264 0.370733 18.7386 0.158648 18.5822 0.0022768C18.4258 -0.154095 18.2137 -0.241943 17.9925 -0.241943C17.7714 -0.241943 17.5593 -0.154095 17.403 0.0022768C17.2466 0.158648 17.1587 0.370733 17.1587 0.591876V16.2154C17.1593 16.8543 17.4133 17.4669 17.865 17.9187C18.3166 18.3706 18.9291 18.6248 19.568 18.6257H323.856V16.9559Z"
          fill="#c4c4c4"
        />
      </g>
    </svg>
  );
};

export default Loading;
