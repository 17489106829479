import React from "react";
import ReactDOM from "react-dom";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import config from "src/config";
import App from "./App";
import CognitoProvider from "./CognitoAuth/Provider";
import { ToastContainer } from "./components/toast-notification";
import SonicBrandingContent from "./pages/Sonic-branding";
import UserGuideContent from "./pages/User-guide";
import HelpCentreContent from "./pages/Help-centre";
import AssetContent from "./pages/Assets";

const router = createBrowserRouter([
  {
    path: "/list/:organisationId",
    element: <App />,
  },
  {
    path: "/sonic-branding/:brandingId/:pageName",
    element: <SonicBrandingContent />,
  },
  {
    path: "/assets/:brandingId/:pageName",
    element: <AssetContent />,
  },
  {
    path: "/user-guide/:brandingId/:pageName",
    element: <UserGuideContent />,
  },
  {
    path: "/help-centre/:brandingId/:pageName",
    element: <HelpCentreContent />,
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <CognitoProvider
      userPoolId={config.cognito.userPoolId}
      clientId={config.cognito.clientId}
    >
      <ToastContainer theme="light" />
      <RouterProvider router={router} />
    </CognitoProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
