/** @jsxImportSource @emotion/react */
import React, { ReactElement, useEffect, useState } from "react";
import Lottie from "react-lottie-player";
import { useAuth } from "src/CognitoAuth/useAuth";
import getOrganisation from "src/api/get-organisation";
import backgroundLinesAnimation from "../../assets/animations/bars_bg.json";
import style from "./styles";
import { BackButton } from "../back-button";

const ContentHeader = (): ReactElement => {
  const { getUserAccessToken, organisationId } = useAuth();
  const [logo, setLogo] = useState<string>();

  useEffect(() => {
    void (async () => {
      const accessToken = (await getUserAccessToken()) || "";
      const response = await getOrganisation(organisationId, accessToken);
      setLogo(response?.primaryLogo);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId]);

  return (
    <>
      <div css={style.backBtnContainer}>
        <BackButton />
      </div>
      <div css={style.headerContainer}>
        <div css={style.orgLogoContainer}>
          {logo && <img src={logo} alt="org logo" css={style.orgLogo} />}
        </div>
        <Lottie
          animationData={backgroundLinesAnimation}
          style={{ width: 1300, height: 175 }}
          loop
          play
        />
      </div>
    </>
  );
};

export default ContentHeader;
