import { ReactElement } from "react";

const DownloadIcon = ({ fill = "white" }): ReactElement => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.6819 31.1587L17.0679 24.5447C16.8927 24.3695 16.8927 24.0847 17.0679 23.9096L18.2475 22.73C18.4226 22.5549 18.7074 22.5549 18.8825 22.73L23.6826 27.53C23.8577 27.7051 24.1425 27.7051 24.3176 27.53L29.1176 22.73C29.2928 22.5549 29.5776 22.5549 29.7527 22.73L30.9323 23.909C31.1074 24.0841 31.1074 24.3689 30.9323 24.544L24.3183 31.1581C24.1431 31.3332 23.8583 31.3332 23.6832 31.1581L23.6819 31.1587Z"
        fill={fill}
      />
      <path
        d="M24.8336 14H23.1658C22.9178 14 22.7168 14.201 22.7168 14.449V29.2128C22.7168 29.4608 22.9178 29.6618 23.1658 29.6618H24.8336C25.0816 29.6618 25.2826 29.4608 25.2826 29.2128V14.449C25.2826 14.201 25.0816 14 24.8336 14Z"
        fill={fill}
      />
      <path
        d="M33.551 31.4248H14.449C14.201 31.4248 14 31.6258 14 31.8738V33.5416C14 33.7896 14.201 33.9906 14.449 33.9906H33.551C33.799 33.9906 34 33.7896 34 33.5416V31.8738C34 31.6258 33.799 31.4248 33.551 31.4248Z"
        fill={fill}
      />
    </svg>
  );
};

export default DownloadIcon;
