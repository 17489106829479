import { useState, useEffect } from "react";

const useAudioPlayer = () => {
  const [duration, setDuration] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [playing, setPlaying] = useState(false);
  const [clickedTime, setClickedTime] = useState<number>(0);
  const [currentAudioId, setCurrentAudioId] = useState<string>("");
  const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement>();
  const [currentVolume, setCurrentVolume] = useState<number>(0.3);

  useEffect(() => {
    currentAudio?.pause();
    const newAudio = document.getElementById(
      currentAudioId
    ) as HTMLAudioElement;
    setCurrentAudio(newAudio);

    const setAudioData = () => {
      setDuration(newAudio?.duration);
      setCurrentTime(newAudio?.currentTime);
    };

    const setAudioTime = () => setCurrentTime(newAudio.currentTime);

    newAudio?.addEventListener("loadeddata", setAudioData);
    newAudio?.addEventListener("timeupdate", setAudioTime);

    if (newAudio) newAudio.volume = currentVolume;

    if (playing) newAudio?.play();
    else newAudio?.pause();

    // effect cleanup
    return () => {
      newAudio?.removeEventListener("loadeddata", setAudioData);
      newAudio?.removeEventListener("timeupdate", setAudioTime);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAudioId, currentVolume]);

  useEffect(() => {
    if (!currentAudio) return;
    if (playing) currentAudio.play();
    else currentAudio.pause();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing]);

  useEffect(() => {
    if (!currentAudio) {
      const newAudio = document.getElementById(
        currentAudioId
      ) as HTMLAudioElement;
      if (newAudio) newAudio.volume = currentVolume;
      return;
    }
    currentAudio.volume = currentVolume;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVolume]);

  useEffect(() => {
    if (!currentAudio) return;

    if (clickedTime && clickedTime !== currentTime) {
      currentAudio.currentTime = clickedTime;
      setClickedTime(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedTime]);

  const updatePlaying = async (
    selectedAudioId: string,
    setAudioPlaying: boolean
  ) => {
    if (selectedAudioId !== currentAudioId) {
      setCurrentAudioId(selectedAudioId);
    }
    setPlaying(setAudioPlaying);
  };

  return {
    currentTime,
    duration,
    playing,
    setClickedTime,
    setAudioId: setCurrentAudioId,
    updatePlaying,
    currentVolume,
    setCurrentVolume,
  };
};

export default useAudioPlayer;
