import { css } from "@emotion/react";
import "./index.css";
import { queries } from "@songtradr/spa-common";
import theme from "../../../theme";

export default {
  navWrapper: css`
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    z-index: 9999;
    top: 0;
    left: 0;
    position: fixed;
    height: 64px;
    width: 100vw;
    color: white;
    background-color: #000;
    font-family: ${theme.fonts.sofiaPro};
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  `,
  userIcon: css`
    margin: 1rem 1rem 1rem 0;
    background: #ff4e00;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    font-weight: 700;
    align-items: center;
    line-height: 1.25rem;
    cursor: pointer;
    a {
      color: white;
      text-decoration: none;
      vertical-align: middle;
      line-height: 12px;

      &:hover {
        text-decoration: none;
      }
    }
  `,
  menuIconWrapper: css`
    cursor: pointer;
    border-radius: 50%;
    padding: 0.375rem;
    padding-top: 1rem;
    text-align: center;
    position: relative;
    margin-right: 2rem;
    z-index: 9999;
  `,
  right: css`
    flex: 1;
    align-items: flex-end;
    text-align: right;

    a {
      &:hover {
        text-decoration: none;
      }
    }

    .username {
      vertical-align: middle;
      line-height: 12px;
      a {
        vertical-align: middle;
        line-height: 25px;
        color: ${theme.colors.white};
        font-size: 0.875rem;
        cursor: pointer;
        display: none;
        @media ${queries.medium} {
          display: inline-block;
        }
      }
    }
    .user-icon {
      a {
        vertical-align: middle;
        line-height: 32px;
        color: ${theme.colors.black};
        img {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
        }
      }
      .user-no-image {
        background: ${theme.colors.secondary.userIconBackground};
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
      }
      margin: 1rem 1rem 1rem 0;
      text-align: center;
      font-weight: 800;
      align-items: center;
      line-height: 1.25rem;
      cursor: pointer;
    }
    div {
      display: inline-block;
    }
    .seperator {
      width: 2rem;
      height: 0;
      border: 1px solid rgba(255, 255, 255, 0.15);
      transform: rotate(90deg);
      flex: none;
      order: 1;
      flex-grow: 0;
      margin-left: 1rem;
      margin-bottom: 4px;
      display: none;
      @media ${queries.medium} {
        display: inline-block;
      }
    }
    .super-admin {
      display: block;
      font-size: 12px;
      font-weight: 400;
      color: rgb(173, 173, 173);
      text-align: left;
    }
    .menu-icon-wrapper {
      cursor: pointer;
      border-radius: 50%;
      vertical-align: middle;
      padding: 0.375rem;
      width: 2rem;
      height: 2rem;
      text-align: center;
      position: relative;
      margin-right: 1rem;

      @media ${queries.medium} {
        margin-right: 2rem;
      }
      &:hover {
        background: rgba(255, 255, 255, 0.16);
      }
      @media ${queries.medium} {
        margin-left: 10px;
      }
      svg {
        vertical-align: top;
      }
    }
  `,
  left: css`
    display: flex;
  `,
  username: css`
    margin-right: 1rem;
  `,
  userRole: css`
    font-size: 0.75rem;
    color: #adadad;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0;
    text-align: left;
  `,
  dropdownMenu: css`
    padding-top: 24px;
    padding-bottom: 24px;
    filter: drop-shadow(0 4px 16px rgba(0, 0, 0, 0.16));
    top: 12px;
    border-radius: 0 !important;
    @media ${queries.medium} {
      border-radius: 10px !important;
      top: -2px;
    }
    li {
      a {
        font-family: ${theme.fonts.sofiaPro};
        color: ${theme.colors.secondary.blueOnBlack};
      }
      &:not(.ant-dropdown-menu-item-divider) {
        padding: 0;
        height: 46px;
        font-weight: 500;
        span {
          padding: 11.5px 0 11.5px 24px;
        }
        &:hover {
          background-color: ${theme.colors.secondary.bigSky};
        }
      }
      &.ant-dropdown-menu-item-divider {
        border: 1px solid ${theme.colors.secondary.greyRoom};
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
      a:hover {
        text-decoration: none;
      }
      &.sign-out-link span {
        font-family: ${theme.fonts.sofiaPro};
        font-weight: 500;
      }
    }
  `,
  menu: css`
    background-color: red;
  `,
  menuItem: css`
    text-decoration: none;
  `,
  logo: css`
    padding: 1rem 0 1rem 2rem;
    margin: 0;
    height: auto;
  `,
  orgLogo: css`
    height: 32px;
    margin: 15px auto;
    display: block;
  `,
};
