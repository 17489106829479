import axios, { CancelTokenSource } from "axios";
import { IOrganisationMember, eApplicationType } from "./interfaces";
import {
  getAuthHeader,
  getAxiosOptions,
  getJsonContentHeader,
} from "../headers";
import getOrganisationUserUrl from "../../components/hamburger-menu/utils/organisation-members-url-helper";

let call: CancelTokenSource;
const getOrganisationMember = async (
  accessToken: string,
  orgId: string,
  currentUserId: string
): Promise<IOrganisationMember> => {
  call = axios.CancelToken.source();
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
    cancelToken: call.token,
  };

  const response: IOrganisationMember = await axios
    .get(getOrganisationUserUrl(orgId, currentUserId), options)
    .then(({ data }: { data: IOrganisationMember }) => data);
  response.isCurrentUser = currentUserId === response.auth0UserId;
  response.hasMusicSubscription =
    response.applications.includes(eApplicationType.LibraryReadOnly) ||
    response.applications.includes(eApplicationType.LibraryFull);
  response.hasProjectsSubscription =
    response.applications.includes(eApplicationType.ProjectsClient) ||
    response.applications.includes(eApplicationType.ProjectsInternal);
  return response;
};

export default getOrganisationMember;
