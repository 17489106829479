import { ReactElement } from "react";

const DocumentIcon = (): ReactElement => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.5708 42.0007H10.6613C10.2957 42.0007 10 41.705 10 41.3394V28.5421C10 28.1765 10.2966 27.8809 10.6613 27.8809H13.1174C13.483 27.8809 13.7786 28.1775 13.7786 28.5421V37.5598C13.7786 37.9254 14.0743 38.2211 14.4399 38.2211H25.5708C25.9364 38.2211 26.2321 38.5168 26.2321 38.8824V41.3385C26.2321 41.704 25.9364 41.9997 25.5708 41.9997V42.0007Z"
        fill="white"
      />
      <path
        d="M37.9213 42H32.259C31.8934 42 31.5977 41.7043 31.5977 41.3387V38.8826C31.5977 38.517 31.8934 38.2214 32.259 38.2214H34.1426C34.5082 38.2214 34.8039 37.9257 34.8039 37.5601V20.9851C34.8039 20.8122 34.7359 20.646 34.615 20.5222L24.2719 9.97701C24.1472 9.85043 23.9772 9.77863 23.7996 9.77863H14.4399C14.0743 9.77863 13.7786 10.0743 13.7786 10.4399V19.8959C13.7786 20.2615 13.483 20.5572 13.1174 20.5572H10.6613C10.2957 20.5572 10 20.2615 10 19.8959V6.66126C10 6.29568 10.2957 6 10.6613 6H25.3866C25.5642 6 25.7342 6.07179 25.8589 6.19838L38.3936 18.9787C38.5145 19.1024 38.5825 19.2687 38.5825 19.4415V41.3387C38.5825 41.7043 38.2869 42 37.9213 42Z"
        fill="white"
      />
    </svg>
  );
};

export default DocumentIcon;
