/* eslint-disable jsx-a11y/iframe-has-title */
/** @jsxImportSource @emotion/react */
import React, { ReactElement, useState } from "react";
import LoadingSpinner from "../loading-spinner";
import styles from "./styles";

interface IProps {
  url?: string;
  width?: number;
  height?: number;
}

const IFrame = ({ url, width, height }: IProps): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  return (
    <div css={styles.iframeContainer(height || 650, width || 100)}>
      {isLoading ? (
        <div>
          <LoadingSpinner />
        </div>
      ) : null}
      <iframe
        css={styles.iframe}
        src={url}
        allow="encrypted-media"
        onLoad={() => setIsLoading(false)}
      />
    </div>
  );
};

export default IFrame;
