import { css, SerializedStyles } from "@emotion/react";
import { queries } from "@songtradr/spa-common/lib/utils";
import theme from "../../../theme";

export default {
  app: css`
    display: flex;
    align-items: center;
    min-height: 100vh;
    min-width: 100%;
    flex-direction: column;
    background-color: ${theme.colors.background.basalt};

    @media ${queries.xxLarge} {
      align-items: center;
    }
  `,
  headerBackBtn: css`
    display: none;

    @media ${queries.medium} {
      display: flex;
      font-size: 1rem;
      margin-bottom: 3rem;
    }
  `,
  map: css`
   width: 500px;
   height: 100px;
   padding-top: 3px;
   border-radius: 10px;
   z-index: 1;

   .leaflet-container {
     border-radius: 10px;
   },
  `,
  header: css`
    margin-bottom: 2rem !important;
  `,
  content: css`
    background-color: ${theme.colors.background.basalt};
    width: 100%;
    max-width: 1440px;
    padding: 0 3rem;
  `,
  backBtnContainer: css`
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
  `,
  backBtn: css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border-radius: 16px;
    background: transparent;
    margin-right: 4px;
    border: none;
    cursor: pointer;

    svg {
      margin-right: 3px;
    }
    :hover {
      background-color: white;
      path {
        fill: #141414;
      }
    }
  `,
  backText: css`
    font-size: 0.875rem;
    font-weight: 400;
    font-family: ${theme.fonts.sofiaPro} !important;
    background: none;
    color: white;
    border: none;
    cursor: pointer;

    :hover {
      color: white;
      text-decoration: underline;
    }
  `,
  footer: css`
    background-color: ${theme.colors.background.basalt};
  `,
  logoContainer: css`
    display: flex;
    flex-direction: column;
    margin-top: 64px;
    margin-left: 0;
    margin-bottom: 64px;
    width: 100%;
    z-index: 1000;
  `,
  orgLogoContainer: css`
    z-index: 100;
  `,
  animationBg: css`
    position: absolute;
    right: 0;
  `,
  orgLogo: css`
    height: 100%;
    object-fit: cover;
    display: block;
    width: auto;
    max-height: 165px;
    max-width: 100%;
  `,
  trackContainer(width: number): SerializedStyles {
    return css`
      background: ${theme.colors.secondary.darkGrayCardBackground};
      border-radius: 10px;
      width: ${width}%;
      margin-bottom: 16px;
      .track {
        display: block;
        padding: 20px;
      }
    `;
  },
  fileUploaderContainer(width: number): SerializedStyles {
    return css`
      background: ${theme.colors.secondary.darkGrayCardBackground};
      border-radius: 10px;
      width: ${width}%;
    `;
  },
};
