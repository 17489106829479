/* eslint-disable no-underscore-dangle */
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import { Navigate, useParams } from "react-router-dom";
import NavigationBar from "./components/navigation-bar";
import NoContentScreen from "./components/no-content";
import styles from "./styles";
import "./index.css";
import "./theme/index.css";
import LandingPage from "./components/landing-page";
import LoadingSpinner from "./components/loading-spinner";
import { ISonicBranding, ISonicBrandingResponse } from "./interfaces";
import config from "./config";
import { useAuth } from "./CognitoAuth/useAuth";

function App() {
  const PROJECT_ID = config.sanityProjectID;
  const DATASET = config.sanityDataset;
  const { organisationId } = useParams();
  const { setOrganisationId } = useAuth();
  if (organisationId) {
    setOrganisationId(organisationId);
  }

  document.title = "Resources";
  const QUERY = encodeURIComponent(
    `*["${organisationId}" in organisationIdArray || organisationId == "${organisationId}" || organisationsSelectAll == true]`
  );
  const URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`;

  const emptySonicBrandingItems: Array<ISonicBranding> = [];
  const [state, setState] = useState({
    sonicBrandingItems: emptySonicBrandingItems,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getUrlName = (value: string) => {
    let urlName = value;
    urlName = urlName.replace(/\s+/g, "-").toLowerCase();
    encodeURIComponent(urlName);
    return urlName;
  };

  useEffect(() => {
    fetch(URL)
      .then((res) => res.json())
      .then(({ result }) => {
        const newSonicBranding: Array<ISonicBranding> = [];
        if (result.length > 0) {
          result.forEach((sonicBranding: ISonicBrandingResponse) =>
            newSonicBranding.push({
              id: sonicBranding?._id,
              name: sonicBranding?.name,
              type: sonicBranding._type,
              description: sonicBranding?.description || "",
              lastUpdated: sonicBranding._updatedAt,
            })
          );
        }
        setState({
          sonicBrandingItems: newSonicBranding.sort(
            (a: ISonicBranding, b: ISonicBranding) =>
              b.lastUpdated.localeCompare(a.lastUpdated)
          ),
        });
        setIsLoading(false);
      });
  }, [URL]);

  if (state.sonicBrandingItems?.length === 1) {
    return (
      <Navigate
        replace
        to={`/${state.sonicBrandingItems[0].type}/${
          state.sonicBrandingItems[0].id
        }/${getUrlName(state.sonicBrandingItems[0].type)}`}
      />
    );
  }

  const hasItems = state.sonicBrandingItems?.length > 0;

  return (
    <div>
      <Layout css={styles.app}>
        <Layout.Header css={styles.header}>
          <NavigationBar />
        </Layout.Header>
        <Layout.Content css={styles.content}>
          {isLoading && <LoadingSpinner />}
          {!isLoading && hasItems && (
            <LandingPage sonicBrandingItems={state.sonicBrandingItems} />
          )}
          {!isLoading && !hasItems && <NoContentScreen />}
        </Layout.Content>
      </Layout>
    </div>
  );
}

export default App;
