import { css } from "@emotion/react";

export default {
  headingContainer: css`
    margin-top: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0px 32px 32px -32px rgba(0, 0, 0, 0.5);

    @media all and (min-width: 922px) {
      margin-top: 2rem;
    }
  `,
  primaryHeading: css`
    margin: 1.5rem 0 0;
    font-size: 2rem;
    font-weight: 500;
    line-height: 40px;
    color: white;

    @media all and (min-width: 922px) {
      font-size: 2.5rem;
      line-height: 3rem;
      margin: 0.5rem 0 0;
    }

    @media all and (min-width: 1400px) {
      font-size: 3.5rem;
      line-height: 4rem;
    }
  `,
  secondaryHeading: css`
    margin: 0;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 28px;
    color: white;
  `,
  horizontalDivider: css`
    height: 1px;
    width: 100%;
    background: #222;
    line-height: normal;
    margin-top: 1.5rem;

    @media all and (min-width: 922px) {
      margin-top: 2rem;
    }

    @media all and (min-width: 1400px) {
      margin-top: 2.5rem;
    }
  `,
};
