import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

const MenuIcon = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="menu-icon-title"
    >
      <title id="menu-icon-title">{t("icons##menu")}</title>
      <path
        d="M0.666672 4C0.666672 2.15905 2.15906 0.666667 4.00001 0.666667C5.84096 0.666667 7.33334 2.15905 7.33334 4C7.33334 5.84095 5.84096 7.33333 4.00001 7.33333C2.15906 7.33333 0.666672 5.84095 0.666672 4Z"
        fill="white"
      />
      <path
        d="M0.666672 14C0.666672 12.1591 2.15906 10.6667 4.00001 10.6667C5.84096 10.6667 7.33334 12.1591 7.33334 14C7.33334 15.841 5.84096 17.3333 4.00001 17.3333C2.15906 17.3333 0.666672 15.841 0.666672 14Z"
        fill="white"
      />
      <path
        d="M10.6667 4C10.6667 2.15905 12.1591 0.666667 14 0.666667C15.841 0.666667 17.3333 2.15905 17.3333 4C17.3333 5.84095 15.841 7.33333 14 7.33333C12.1591 7.33333 10.6667 5.84095 10.6667 4Z"
        fill="white"
      />
      <path
        d="M10.6667 14C10.6667 12.1591 12.1591 10.6667 14 10.6667C15.841 10.6667 17.3333 12.1591 17.3333 14C17.3333 15.841 15.841 17.3333 14 17.3333C12.1591 17.3333 10.6667 15.841 10.6667 14Z"
        fill="white"
      />
    </svg>
  );
};

export default MenuIcon;
