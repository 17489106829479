/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";
import "antd/dist/antd.min.css";
import { useAuth } from "src/CognitoAuth/useAuth";
import Subscriptions from "src/constants";
import config from "src/config";
import SongtradrHomeLogoWhite from "../../assets/icons/songtradr-home-logo-white";
import styles from "./styles";
import HamburgerMenu from "../hamburger-menu";

const NavigationBar = (): JSX.Element => {
  const { user, profileImage, isSuperAdmin } = useAuth();

  return (
    <div css={styles.navWrapper} data-testid="header-nav-wrapper">
      <div css={styles.left}>
        <a
          href={config.enterprise.url}
          css={styles.logo}
          data-testid="header-nav-logo"
        >
          <SongtradrHomeLogoWhite css={styles.logo} />
        </a>
      </div>
      <div css={styles.right}>
        <div className="user-icon">
          <a
            href={Subscriptions.Profile.path}
            data-heapid="header-nav-profile-icon"
            data-testid="header-nav-user-icon"
          >
            {profileImage ? (
              <img src={profileImage} alt="profile" />
            ) : (
              <div className="user-no-image"> {user?.name?.charAt(0)} </div>
            )}
          </a>
        </div>
        <div className="username" data-testid="header-nav-user-name">
          <a
            href={Subscriptions.Profile.path}
            data-heapid="header-nav-profile-name"
          >
            {user?.name}
          </a>
          {isSuperAdmin && <div className="super-admin">Super Admin</div>}
        </div>
        <div className="seperator" />
        <HamburgerMenu />
      </div>
    </div>
  );
};

export default NavigationBar;
