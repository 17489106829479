import React, { ReactElement } from "react";

const OverflowMenuIcon = (): ReactElement => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.3812 34.5L25.6188 34.5C26.1055 34.5 26.5 34.1082 26.5 33.625L26.5 30.375C26.5 29.8918 26.1055 29.5 25.6188 29.5L22.3812 29.5C21.8945 29.5 21.5 29.8918 21.5 30.375L21.5 33.625C21.5 34.1082 21.8945 34.5 22.3812 34.5Z"
        fill="white"
      />
      <path
        d="M22.3812 26.5L25.6188 26.5C26.1055 26.5 26.5 26.1082 26.5 25.625L26.5 22.375C26.5 21.8918 26.1055 21.5 25.6188 21.5L22.3812 21.5C21.8945 21.5 21.5 21.8918 21.5 22.375L21.5 25.625C21.5 26.1082 21.8945 26.5 22.3812 26.5Z"
        fill="white"
      />
      <path
        d="M22.3812 18.5L25.6188 18.5C26.1055 18.5 26.5 18.1082 26.5 17.625L26.5 14.375C26.5 13.8918 26.1055 13.5 25.6188 13.5L22.3812 13.5C21.8945 13.5 21.5 13.8918 21.5 14.375L21.5 17.625C21.5 18.1082 21.8945 18.5 22.3812 18.5Z"
        fill="white"
      />
    </svg>
  );
};

export default OverflowMenuIcon;
