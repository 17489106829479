import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  buttonContainer: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 32px;
    height: 32px;
    cursor: pointer;
    overflow: hidden;
    transition-duration: 0.3s;
    background-color: transparent;
    border: 1px solid ${theme.colors.background.darkGray};
    border-radius: 40px;
    font-size: 1rem;
    line-height: 1rem;

    .buttonText {
      transition: all 0.3s;
      opacity: 0;

      color: ${theme.colors.white};
    }

    @media ${queries.medium} {
      &:hover {
        width: 5.25rem;
        border-radius: 40px;

        .buttonText {
          opacity: 1;
        }
      }
    }
  `,
  span: css`
    margin-top: 2px;
    transition-duration: 0.3s;

    svg {
      margin-right: 0.75rem;
    }
  `,
};
