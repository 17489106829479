import { css } from "@emotion/react";

export default {
  headerContainer: css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  `,
  backBtnContainer: css`
    display: flex;
    align-items: center;
    margin: 3rem 0;
  `,
  orgLogoContainer: css`
    z-index: 100;
  `,
  orgLogo: css`
    height: 100%;
    object-fit: cover;
    display: block;
    width: auto;
    max-height: 165px;
  `,
};
