/** @jsxImportSource @emotion/react */
import React from "react";
import { Slider } from "antd";
import styles from "../../styles";

interface IProgressBarProps {
  currentTime: number;
  setCurrentTime: React.Dispatch<React.SetStateAction<number>>;
  duration: number;
  disabled?: boolean;
}

const ProgressBar = ({
  currentTime,
  setCurrentTime,
  duration,
  disabled = false,
}: IProgressBarProps) => {
  const handleScrub = (scrubTime: number) => {
    setCurrentTime(scrubTime);
  };

  return (
    <div css={styles.trackBar(disabled)}>
      <Slider
        data-testid="track-progress-bar"
        min={0}
        max={duration}
        step={0.01}
        value={currentTime}
        disabled={disabled}
        onChange={(e) => handleScrub(e)}
      />
    </div>
  );
};

export default ProgressBar;
