import { css, SerializedStyles } from "@emotion/react";

export default {
  iframeContainer(height: number, width: number): SerializedStyles {
    return css`
      width: ${width}%;
      height: ${height}px;
      overflow: hidden;
    `;
  },
  iframe: css`
    width: 100%;
    height: 100%;
    border: none;
    margin-top: 24px;
    margin-bottom: 24px;
  `,
};
