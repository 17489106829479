import { IUserPermissions } from "src/Platform/EnterprisePlatform";
import { eApplicationType } from "../interfaces";

export const getOrgIdsFromDecodedToken = (decodedToken: {
  [id: string]: any;
}) => {
  const cognitoGroups: string[] = decodedToken["cognito:groups"];
  if (cognitoGroups) {
    if (cognitoGroups.includes("superadmin")) {
      return [];
    }

    const orgMemberCognitoGroups = cognitoGroups.filter((g) =>
      g.startsWith("org:member:")
    );
    if (orgMemberCognitoGroups?.length > 0) {
      return orgMemberCognitoGroups.map((g) => g.replace("org:member:", ""));
    }
  }
  return [];
};

export const getUserPermissions = (
  isSuperAdmin: boolean,
  applications: eApplicationType[]
): IUserPermissions => {
  let canAccessPortal = false;
  let canAccessProjects = false;
  let canAccessTeams = false;
  let canAccessMusicSubscription = false;
  let canAccessRequests = false;
  let canAccessInsights = false;
  let canAccessMyContent = false;
  let canAccessValidate = false;

  if (isSuperAdmin) {
    return {
      canAccessPortal: true,
      canAccessProjects: true,
      canAccessTeams: true,
      canAccessMusicSubscription: true,
      canAccessRequests: true,
      canAccessInsights: true,
      canAccessMyContent: true,
      canAccessValidate: true,
    };
  }

  applications.forEach((application) => {
    // eslint-disable-next-line default-case
    switch (application) {
      case eApplicationType.Portal:
        canAccessPortal = true;
        break;
      case eApplicationType.ProjectsInternal:
        canAccessProjects = true;
        break;
      case eApplicationType.LibraryFull:
        canAccessMusicSubscription = true;
        break;
      case eApplicationType.ProjectsClient:
        canAccessProjects = true;
        break;
      case eApplicationType.LibraryReadOnly:
        canAccessMusicSubscription = true;
        break;
      case eApplicationType.Validate:
        canAccessValidate = true;
        break;
      case eApplicationType.Resources:
        canAccessMyContent = true;
        break;
      case eApplicationType.Requests:
        canAccessRequests = true;
        break;
      case eApplicationType.Insights:
        canAccessInsights = true;
        break;
      case eApplicationType.TeamManagement:
        // TODO: Should we make these applications explicit on an org-admin to avoid this inferring?
        canAccessPortal = true;
        canAccessTeams = true;
        canAccessRequests = true;
        canAccessInsights = true;
        canAccessMyContent = true;
        canAccessValidate = true;
        break;
    }
  });

  return {
    canAccessPortal,
    canAccessTeams,
    canAccessProjects,
    canAccessMusicSubscription,
    canAccessRequests,
    canAccessInsights,
    canAccessMyContent,
    canAccessValidate,
  };
};

export default getOrgIdsFromDecodedToken;
