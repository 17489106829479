import { css } from "@emotion/react";
import theme from "../../../theme";

export default {
  header: css`
    color: ${theme.colors.white};
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 250;
    font-size: 3rem;
    line-height: 3.5rem;
    letter-spacing: -2%;
    margin-bottom: 48px;
  `,
  sectionHeader: css`
    color: ${theme.colors.white};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 3.3px;
    text-transform: uppercase;
    margin-bottom: 24px;
    margin-top: 48px;
  `,
  backBtnContainer: css`
    margin: 2rem 0;
  `,
  table: css`
    // remove borders
    .ant-table-cell {
      border: none;
    }
    .ant-table-cell::before {
      display: none;
    }

    // background and text
    .ant-table-cell {
      background-color: ${theme.colors.background.basalt};
      font-family: ${theme.fonts.sofiaPro};
      font-weight: 400;
    }
    .ant-table-thead .ant-table-cell {
      color: ${theme.colors.white};
      font-size: 1rem;
      line-height: 1.25rem;
      background-color: #222;
      font-weight: 700;
      border-right: 1px solid ${theme.colors.background.basalt};
      :last-child {
        border-right: none;
      }
    }
    .ant-table-tbody .ant-table-cell {
      color: ${theme.colors.white};
      font-size: 1rem;
      line-height: 1.5rem;
    }

    // hover colour
    .ant-table-row:hover .ant-table-cell {
      background-color: ${theme.colors.background.basalt};
    }
  `,
  tableActions: css`
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 16px;
  `,
  copyButton: css`
    width: 48px;
    height: 48px;
    padding: 14px;
    cursor: pointer;

    :hover {
      svg {
        path {
          fill: ${theme.colors.functional.brightOrange};
        }
      }
    }
  `,
  viewButton: css`
    height: auto;
    padding: 14px;
    border-radius: 8px;
    padding: 16px 24px;
    border: 0;
    outline: none;
    background-color: white;
    font-weight: 700;

    :hover {
      background: ${theme.colors.background.fadeToGrey};
      color: initial;
      border: none;
    }
  `,
  tooltip: css`
    .ant-tooltip-inner {
      position: relative;
      top: 0.2rem !important;
      color: ${theme.colors.black};
      font-size: 0.75rem;
      font-weight: 700;
      min-height: 26px !important;
      border-radius: 4px;
      line-height: 0.9rem;
      height: 26px;
    }

    .ant-tooltip-arrow {
      z-index: 2;
      transform: translateX(-50%);
      top: 22px;
    }

    .ant-tooltip-arrow-content {
      z-index: 2;
      width: 0.75rem;
      height: 0.75rem;
      margin-top: 0.8rem;
    }
  `,
};
