/* eslint-disable jsx-a11y/media-has-caption  */
/* eslint-disable jsx-a11y/iframe-has-title */
/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";
import styles from "./styles";

interface IProps {
  src?: string;
  width?: number;
}

const ImageComponent = ({ src, width }: IProps): ReactElement => {
  return (
    <div css={styles.imageContainer(width || 100)}>
      <img alt="sanity-img" src={src} css={styles.imageComponent} />
    </div>
  );
};

export default ImageComponent;
