/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/media-has-caption */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import {
  getIsDesktop,
  getIsMobile,
  useWindowSize,
} from "@songtradr/spa-common";
import { useTranslation } from "react-i18next";
import DownloadIcon from "src/assets/icons/download-icon";
import { Tooltip, Typography } from "antd";
import { getAuthHeader, getJsonContentHeader } from "src/api/headers";
import axios from "axios";
import { useAuth } from "src/CognitoAuth/useAuth";
import * as moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import styles from "./styles";
import useAudioPlayer from "./useAudioPlayer";
import ControlButton from "../control-buttons/base-button";
import ProgressCircle from "./components/progress-circle";
import ProgressBar from "./components/progress-bar";
import { errorToast } from "../toast-notification";
import VolumeControl from "./components/volume-control";

momentDurationFormatSetup(moment);

interface IProps {
  url: string;
  fileName: string;
  handleDownloadClick?: (url?: string, fileInfo?: IFileInfo) => void;
  columnWidth?: number;
  isDownloadable?: boolean;
  multiColumns?: boolean;
}

export interface IFileInfo {
  fileName: string;
  type: string;
}

// This is a wrapper around the Waveform.js library for easier usage within React.
export default function MusicPlayer({
  url,
  fileName,
  handleDownloadClick,
  columnWidth = 100,
  isDownloadable,
  multiColumns = false,
}: IProps): JSX.Element {
  useWindowSize();
  const isDesktop = getIsDesktop();
  const isMobile = getIsMobile();
  const { t } = useTranslation();
  const isUploadedUrl = url.startsWith("https://cdn.sanity.io");
  const [fileInfo, setFileInfo] = useState<IFileInfo>({
    fileName: "",
    type: "",
  });
  const { getUserAccessToken, organisationId } = useAuth();
  const [downloadUrl, setDownloadUrl] = useState<string>("");
  const [disablePlayer, setDisablePlayer] = useState<boolean>(false);
  const {
    currentTime,
    duration,
    playing,
    updatePlaying,
    setClickedTime,
    setAudioId,
    currentVolume,
    setCurrentVolume,
  } = useAudioPlayer();
  const playButtonWidth = 48;
  const audioId = `audio-${url}`;
  const isCompactPlayer = columnWidth < 49;

  useEffect(() => {
    const fileType = isUploadedUrl
      ? url.substring(url.lastIndexOf(".") + 1)
      : "";
    setFileInfo({
      fileName,
      type: fileType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAudioId(audioId);
  }, [audioId, setAudioId]);

  const getAssetS3Url = async () => {
    if (isUploadedUrl) {
      setFileInfo({ fileName, type: url.substring(url.lastIndexOf(".") + 1) });
      setDownloadUrl(url);
      return;
    }

    const accessToken = await getUserAccessToken();
    if (accessToken) {
      const options = {
        headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
      };
      const data = {
        organisationId,
      };

      const response = await axios.post(url, data, options).catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            errorToast({
              message:
                "Sorry, you do not have permission to play this track. Please check it belongs to this organisation and the 'Share with client' option has been selected.",
            });
          }
        }
      });

      if (!response) {
        setDisablePlayer(true);
        return;
      }

      const s3Params = response.data.url.split("/").pop();
      const s3FileName = s3Params.substring(0, s3Params.indexOf("?"));

      setFileInfo({
        fileName: s3FileName,
        type: s3FileName.split(".").pop(),
      });
      setDownloadUrl(response.data.url);
    } else {
      errorToast({ message: "Error, unable to authenticate this user." });
    }
  };

  useEffect(() => {
    getAssetS3Url();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderProgressCircle = (
    progress: number,
    isPlaying: boolean,
    isLoading: boolean
  ) => {
    if (isPlaying && isLoading) {
      return <ProgressCircle width={playButtonWidth} progress={0.15} spin />;
    }

    return <ProgressCircle width={playButtonWidth} progress={progress} />;
  };

  const hidePlayerAndSound = () => {
    return isMobile || (!isDesktop && multiColumns);
  };

  const hideDownloadOnMultiColumns = () => {
    return isMobile && multiColumns;
  };

  return (
    <div css={styles.container(100)} id="music-player">
      <div css={styles.player}>
        <audio id={audioId} crossOrigin="anonymous" src={downloadUrl} />
        <div css={styles.playButtonContainer}>
          <ControlButton
            css={[styles.control, playing ? styles.playing : styles.paused]}
            label={playing ? "Pause track" : "Play track"}
            onClick={() => {
              if (playing) updatePlaying(audioId, false);
              else updatePlaying(audioId, true);
            }}
            data-testid={
              playing ? "music-player-pause-button" : "music-player-play-button"
            }
            disabled={disablePlayer}
          >
            {renderProgressCircle(
              duration ? currentTime / duration : 0,
              playing,
              false
            )}
          </ControlButton>
        </div>
        <div
          css={styles.fileNameContainer}
          style={{ flex: hidePlayerAndSound() ? 1 : "initial" }}
        >
          <Tooltip
            getPopupContainer={(triggerNode) => triggerNode}
            title={fileName}
            placement="top"
          >
            <Typography.Text css={styles.fileName}>{fileName}</Typography.Text>
          </Tooltip>
        </div>
        {!isCompactPlayer && !hidePlayerAndSound() ? (
          <>
            <div css={styles.currentTime}>
              {moment
                .duration(currentTime, "seconds")
                .format("mm:ss", { trim: false })}
            </div>
            <ProgressBar
              currentTime={currentTime}
              duration={duration}
              setCurrentTime={setClickedTime}
              disabled={disablePlayer}
            />
          </>
        ) : null}
        {!hidePlayerAndSound() && (
          <div css={styles.duration}>
            {moment
              .duration(duration, "seconds")
              .format("mm:ss", { trim: false })}
          </div>
        )}
        {!hidePlayerAndSound() && (
          <VolumeControl
            currentVolume={currentVolume}
            setCurrentVolume={setCurrentVolume}
            disabled={disablePlayer}
          />
        )}
        {handleDownloadClick && isDownloadable ? (
          <>
            {hideDownloadOnMultiColumns() && !isDesktop ? null : (
              <button
                type="button"
                css={styles.tabletDownloadButton}
                aria-label={t("Download")}
                onClick={() => handleDownloadClick(downloadUrl, fileInfo)}
              >
                <DownloadIcon />
              </button>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
}
