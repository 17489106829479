/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";
import styles from "./styles";

interface ITitleProps {
  title?: string;
  isLarge?: boolean;
}

const Title = ({ title, isLarge }: ITitleProps): ReactElement => {
  return <div css={styles.title(isLarge || false)}>{title}</div>;
};

export default Title;
