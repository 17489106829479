import axios from "axios";
import handleDownloadClick from "src/components/music-player/util";
import getAuthHeader from "../headers/get-auth-header";
import getJsonContentHeader from "../headers/get-json-content-header";

export const getPresignedUrl = async (
  url: string,
  fileName: string,
  fileType: string,
  organisationId: string,
  accessToken: string
) => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
  };

  const body = {
    organisationId,
  };

  const response = await axios.post(url, body, options);

  return response.data.url;
};

async function downloadFileWithApiUrl(
  url: string,
  fileName: string,
  fileType: string,
  organisationId: string,
  accessToken: string
) {
  const presignedDownloadUrl = await getPresignedUrl(
    url,
    fileName,
    fileType,
    organisationId,
    accessToken
  );

  await handleDownloadClick(presignedDownloadUrl, {
    fileName,
    type: fileType,
  });
}

export default downloadFileWithApiUrl;
