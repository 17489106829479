import * as Sentry from "@sentry/browser";
import jwt_decode from "jwt-decode";
import { CognitoIdToken } from "amazon-cognito-identity-js";

export enum Tier {
  Admin = "admin",
  Free = "free",
  PremiumDownload = "premDownload",
  PremiumStream = "premStream",
  Pro = "pro",
  ZeroCpm = "0cpm",
}

export interface IGuestUser {
  tier: "guest";
  jwt: IEnterpriseJwt;
  sub: string;
  shared_entity_guid: string;
  shared_entity_type: string;
}

export interface IUserPermissions {
  canAccessPortal: boolean;
  canAccessProjects: boolean;
  canAccessTeams: boolean;
  canAccessMusicSubscription: boolean;
  canAccessRequests: boolean;
  canAccessInsights: boolean;
  canAccessMyContent: boolean;
  canAccessValidate: boolean;
}

export enum eCognitoRoles {
  PortalAdmin = "app:role:portal-admin",
  PortalUser = "app:role:portal-user",
  PortalSubscriber = "app:role:portal-subscriber",
  ClientAdmin = "app:role:vinyl-projects-client-admin",
  ClientUser = "app:role:vinyl-projects-client-user",
  ClientSubscriber = "app:role:vinyl-projects-client-subscriber",
  VinylMusicUser = "app:role:vinyl-music-user",
  VinylMusicSubscriber = "app:role:vinyl-music-subscriber",
  SumoAdmin = "app:role:vinyl-music-admin",
}

export function parseGuestJWT(jwt: string): IGuestUser {
  const decoded: any = jwt_decode(jwt);
  // TODO: Validate token issuer here.

  return {
    tier: "guest",
    sub: decoded.sub,
    jwt: decoded,
    shared_entity_guid: decoded.shared_entity_guid,
    shared_entity_type: decoded.shared_entity_type,
  };
}

interface IEnterpriseJwt {
  sub: string;
  "cognito:groups": string[];
  iss: string;
  client_id: string;
  origin_jti: string;
  event_id: string;
  token_use: string;
  scope: string;
  auth_time: number;
  exp: number;
  iat: number;
  jti: string;
  username: string;
}

export interface IEnterpriseUser {
  name: string;
  email: string;
  organisation_id: string;
  tier: string;
  jwt: IEnterpriseJwt;
  isOrganisationAdmin: boolean;
}

export interface IEnterprisePlatformContext {
  hasFinishedAttemptLoadUser: boolean;
  loaded: boolean;
  showingAuth: boolean;
  pretzelUser?: IEnterpriseUser | IGuestUser;
  // features: Features;
  loadData: (key: string) => Promise<string>;
  saveData: (key: string, value: string) => void;
  notify: (message: string) => void;
  openExternalLink: (link: string) => boolean;
  getPlatformAuthorizationToken: () => string;
  setPretzelUser: (user: IEnterpriseUser | IGuestUser) => void;
  unloadApp: () => void;
  logout?: () => void;
  refreshUserToken?: () => Promise<void>;
  registerHotkey: (
    key: string,
    callback: (e?: KeyboardEvent) => void,
    local?: boolean
  ) => void;
  unregisterHotkey: (key: string) => void;
  // This is here because SLOBS needs it... it's gross, but the alternatives are worse
  showAuthorizationWindow: (
    url: string,
    options: { width: number; height: number }
  ) => void;
}

export function parseEnterpriseJWT(
  jwt: string,
  idToken: CognitoIdToken
): IEnterpriseUser {
  const decoded: any = jwt_decode(jwt);

  const { email } = idToken.payload;
  const { name } = idToken.payload;

  Sentry.configureScope((scope) => {
    scope.setUser({
      guid: decoded.username,
      username: email,
    });
  });

  const isAdmin = decoded["cognito:groups"]?.some(
    (role: string) => role === "app:role:portal-admin"
  );
  const orgId = decoded["cognito:groups"][0].replace("org:member:", "");
  const isOrganisationAdmin = decoded["cognito:groups"].some(
    (group: string) => group === "app:role:portal-admin"
  );

  return {
    organisation_id: orgId,
    email,
    name,
    tier: isAdmin ? Tier.Admin : "user",
    jwt: decoded,
    isOrganisationAdmin,
  };
}

export const isUserGuest = (
  user: IEnterpriseUser | IGuestUser
): user is IGuestUser => user?.tier === "guest";
