/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";
import { Button } from "antd";
import ContentHeader from "../content-header";
import style from "./styles";
import config from "../../config";

const NoContentScreen = (): ReactElement => {
  const goToDashboard = () => {
    window.location.replace(config.enterprise.url);
  };

  return (
    <div>
      <ContentHeader />
      <div css={style.ctaContainer}>
        <div css={style.headline}>Nothing to see here!</div>
        <div css={style.body}>
          Nothing to see here as an asset project hasn’t been created yet.
        </div>
        <Button css={style.button} onClick={() => goToDashboard()}>
          Back to Dashboard
        </Button>
      </div>
    </div>
  );
};

export default NoContentScreen;
