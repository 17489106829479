import config from "./config";

export interface IConfig {
  production?: boolean;
  staging?: boolean;
  sanityProjectID: string;
  sanityDataset: string;
  analytics: {
    heapAppID: string;
    heapDomains: string[];
  };
  assetsUrl: string;
  development?: boolean;
  baseSiteSubdomain: string;
  cognito: {
    userPoolId: string;
    clientId: string;
    domain: string;
  };
  auth0: {
    domain: string;
    clientId: string;
    audience: string;
    redirectUri: string;
    appName: string;
    dbConnection?: string;
  };
  recaptcha: {
    key: string;
  };
  organisationSubscriptionsService: {
    url: string;
  };
  projectManagementService: {
    url: string;
  };
  userInteractionService: {
    url: string;
  };
  userMetaDataService: {
    url: string;
  };
  searchService: {
    url: string;
  };
  songCollectionService: {
    url: string;
  };
  songService: {
    url: string;
  };
  songCDNService: {
    url: string;
  };
  musicInsightsProxy: {
    url: string;
  };
  marketplaceService: {
    url: string;
  };
  player: {
    subdomainPrefix?: string;
    baseUrl: string;
    url: string; // This is a calculated field
  };
  playerAPI: {
    url: string;
  };
  enterprise: {
    subdomainPrefix?: string;
    baseUrl: string;
    url: string; // This is a calculated field
  };
  support: {
    url: string;
  };
  assetsSpa: {
    subdomainPrefix?: string;
    baseUrl: string;
    url: string; // This is a calculated field
  };
  oldPlayerOrgIds: string[];
  externalResources: {
    termsOfService: string;
    registrationLearnMore: string;
  };
  webHookUrl: string;
  dataDogToken: string;
  basePathname: string;
  wavAudioBucketUrl: string;
  selectHub: {
    doveOrgId: string;
    unileverOrgId: string;
    sisleyOrgId: string;
    hogarthOrgId: string;
    nespressoOrgId: string;
    reckittOrgId: string;
    niveaOrgId: string;
    zalandoOrgId: string;
    kauflandOrgId: string;
    heinekenOrgId: string;
  };
  supportEmail: string;
  stripePublishableKey: string;
  products: {
    starter: {
      yearly: string;
      monthly: string;
    };
  };
  featureToggles: {
    similarSearch: boolean;
    priceEstimator: string[];
    showComingSoon: boolean;
  };
  videoSubscriptionCode: string;
  defaultProjectsSupportEmail: string;
  s3CloudfrontDistributionHosts: {
    s3Host: string;
    cloudfrontHost: string;
  };
}

export default config as IConfig;
