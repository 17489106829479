import config from "src/config";
import Subscriptions from "src/constants";
import getDefaultPlaylistsUrl from "./subscriptionPlaylistsDefaultUrl";

export const generateSearchLink = (organisationId: string) => {
  let url = "";

  // If org is not listed then use the new player: https://player.enterprise.songtradr.com
  if (!config.oldPlayerOrgIds.includes(organisationId)) {
    url = `${config.player.url}/search`;
  } else {
    url += `${Subscriptions.Library.path}/search`;
  }

  return url;
};

export const generateLibraryLink = (organisationId: string) => {
  let url = "";
  const digitalSubscriptionUrl = getDefaultPlaylistsUrl(organisationId);

  // If org is not listed then use the new player: https://player.enterprise.songtradr.com
  if (!config.oldPlayerOrgIds.includes(organisationId)) {
    url = config.player.url;
  } else {
    url += digitalSubscriptionUrl;
  }

  return url;
};

export const generateSonicBrandingLink = (organisationId: string) => {
  const url = `${config.assetsSpa.url}/list/${organisationId}`;
  return url;
};
