/* eslint-disable no-underscore-dangle */
/** @jsxImportSource @emotion/react */
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { format, parseISO } from "date-fns";
import { Popover } from "antd";
import config from "src/config";
import downloadFileWithApiUrl, {
  getPresignedUrl,
} from "src/api/attachments/download-file-with-api-url";
import { useAuth } from "src/CognitoAuth/useAuth";
import CopyLink from "src/assets/icons/copyLink";
import copy from "copy-to-clipboard";
import { successToast } from "src/components/toast-notification";
import DocumentIcon from "../../assets/icons/document-icon";
import DownloadIcon from "../../assets/icons/download-icon";
import { IUploadedFile } from "../../interfaces";
import styles from "./styles";
import mainStyles from "../../styles";
import OverflowMenuIcon from "../../assets/icons/overflow-menu-icon";
import handleDownloadClick from "../music-player/util";

interface IProps {
  file: IUploadedFile;
  width?: number;
  url?: string;
}

const FileDownload = ({ file, width = 100, url }: IProps): ReactElement => {
  const { getUserAccessToken, organisationId } = useAuth();
  const refContainer = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [showCompactVersion, setShowCompactVersion] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string>("");

  useEffect(() => {
    void (async () => {
      setAccessToken((await getUserAccessToken()) || "");
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isApiUrl = url
    ? url.startsWith(config.projectManagementService.url)
    : false;

  useEffect(() => {
    function handleResize() {
      if (refContainer.current) {
        // 576px is the small breakpoint used in queries from @songtradr/spa-common
        if (refContainer.current.offsetWidth < 576) {
          setShowCompactVersion(true);
        } else {
          setShowCompactVersion(false);
        }
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const Placeholder = () => <div css={styles.placeholderValue}>-</div>;

  const overflowContent = (
    <div css={styles.overflowContent}>
      <div css={styles.header}>File type</div>
      {file?.type ? file.type : <Placeholder />}
      <div css={[styles.header, styles.overflowHeader]}>Uploaded on</div>
      {file?.uploadDate ? (
        format(parseISO(file?.uploadDate), "dd MMM yyyy, HH:mm")
      ) : (
        <Placeholder />
      )}
    </div>
  );

  const handleDownloadFileClick = () =>
    handleDownloadClick(url || "", {
      fileName: file.title || "",
      type: file.type || "",
    });

  return (
    <div
      css={styles.fileContainer(width || 100)}
      id={`file-download-${file?._key}`}
      key={file?._key}
      ref={refContainer}
    >
      <div css={styles.documentIcon}>
        <DocumentIcon />
      </div>
      <div css={styles.detailsContainer}>
        <div css={styles.detailsColumn}>
          <div css={styles.header}>Document</div>
          {file?.title}
        </div>
        {!showCompactVersion ? (
          <div css={styles.detailsColumn}>
            <div css={styles.header}>File type</div>
            {file?.type ? file.type : <Placeholder />}
          </div>
        ) : null}
        {!showCompactVersion ? (
          <div css={styles.detailsColumn}>
            <div css={styles.header}>Uploaded on</div>
            {file?.uploadDate ? (
              format(parseISO(file?.uploadDate), "dd MMM yyyy, HH:mm")
            ) : (
              <Placeholder />
            )}
          </div>
        ) : null}
      </div>

      {showCompactVersion ? (
        <Popover
          content={overflowContent}
          overlayClassName="file-download-popover"
        >
          <span css={styles.overflowButton}>
            <OverflowMenuIcon />
          </span>
        </Popover>
      ) : null}

      <button
        type="button"
        css={[styles.resetButtonStyles, styles.downloadButton]}
        onClick={async (e) => {
          e.stopPropagation();

          if (!url) {
            return;
          }

          const copyUrl = isApiUrl
            ? await getPresignedUrl(
                url,
                file.title || "",
                file.type || "",
                organisationId,
                accessToken
              )
            : url;

          copy(copyUrl);
          successToast({
            message: "Copied to clipboard",
            toastId: "success-toast",
          });
        }}
      >
        <CopyLink />
      </button>

      {isApiUrl ? (
        <span
          tabIndex={0}
          role="link"
          css={styles.downloadButton}
          onClick={() =>
            downloadFileWithApiUrl(
              url || "",
              file.title || "",
              file.type || "",
              organisationId,
              accessToken
            )
          }
          onKeyDown={() =>
            downloadFileWithApiUrl(
              url || "",
              file.title || "",
              file.type || "",
              organisationId,
              accessToken
            )
          }
        >
          <DownloadIcon />
        </span>
      ) : (
        <button
          type="button"
          css={mainStyles.iconButton}
          onClick={handleDownloadFileClick}
        >
          <DownloadIcon />
        </button>
      )}
    </div>
  );
};

export default FileDownload;
