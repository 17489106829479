import { baseURL } from "./baseURL";

export const guestLogin = async (
  guestGuid: string
): Promise<IGuestLogin | Error> => {
  const response = await fetch(`${baseURL}/guest/login`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({
      guest_guid: guestGuid,
    }),
  });

  if (response.ok) {
    const login = (await response.json()) as IGuestLogin;
    return login;
  }

  return Error(
    `Guest Login error - Status: ${response.status}, Message: ${response.statusText}.`
  );
};

export interface IGuestLogin {
  jwt: string;
}
