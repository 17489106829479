import { css, SerializedStyles } from "@emotion/react";
import theme from "../../../theme";

export default {
  title(isLarge: boolean): SerializedStyles {
    return css`
      font-family: ${theme.fonts.sofiaPro};
      color: #fff;
      background-color: #141414;
      font-size: ${isLarge ? "3rem" : "2rem"};
      font-weight: 250;
      line-height: 40px;
      letter-spacing: -0.02em;
      text-align: left;
      padding-bottom: 24px;
      padding-top: 24px;
    `;
  },
};
