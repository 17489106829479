import { css } from "@emotion/react";
import theme from "../../../theme";

export default {
  ctaContainer: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 50px;
    color: white;
  `,
  headline: css`
    font-family: ${theme.fonts.sofiaPro};
    font-size: 2rem;
    font-weight: 250;
    line-height: 2.5rem;
    letter-spacing: -0.02em;
    text-align: center;
  `,
  body: css`
    font-family: ${theme.fonts.sofiaPro};
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 1rem;
  `,
  button: css`
    background-color: #ff4e00;
    color: white;
    height: 56px;
    width: 188px;
    border-radius: 8px;
    padding: 16px 24px 16px 24px;
    font-family: ${theme.fonts.sofiaPro};
    font-size: 1rem;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 2rem;
    border: none;

    :hover,
    :focus {
      background-color: #ff4e00;
      opacity: 0.8;
      color: white;
    }
    :active {
      border: 1px solid white;
    }
  `,
};
