import { css, SerializedStyles } from "@emotion/react";
import { play, pause } from "../../../assets/icons/svg-icons";
import theme from "../../../theme";

export default {
  mapWrapper: css`
    flex: 1;
    width: 1000px;
    height: 750px;
    margin-top: 3.9375rem;
    .leaflet-control-attribution {
      display: none;
    }
    .leaflet-popup-content {
      margin-bottom: 0;
    }
    .leaflet-popup-content-wrapper {
      display: flex;
      justify-content: flex-start;
      width: 250px;
      margin-right: 0 !important;
      background-color: none;
      box-shadow: none;
    }
    .leaflet-container a.leaflet-popup-close-button {
      color: transparent !important;
      font-weight: 700;
      font: "14px/26px Tahoma, Verdana, sans-serif";
    }
    .leaflet-popup-tip-container {
      overflow: visible !important;
      margin-top: 0 !important;
      height: 26px !important;
    }
    .leaflet-popup {
      margin-bottom: 40px !important;
      border: none !important;
      left: -125px !important;
    }
    .leaflet-popup-tip {
      background-color: ${theme.colors.secondary.brightOrange} !important;
      height: 38px !important;
      border: none !important;
      width: 1px !important;
      transform: none !important;
    }
  `,
  marker: css`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    background-color: ${theme.colors.black};
    border: 2px solid ${theme.colors.white};
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
    &:hover {
      z-index: 1;
    }
  `,
  popup: css`
    .custom-popup,
    .leaflet-popup-content-wrapper {
      background: none !important;
      box-shadow: ${theme.colors.secondary.brightOrange} !important;
    }
  `,
  popupContent: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `,
  playBtn(isPlaying: boolean): SerializedStyles {
    return css`
      height: 48px;
      width: 48px;
      border-radius: 32px;
      background-color: ${theme.colors.white};
      border: 2px solid ${theme.colors.white};
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url(${isPlaying ? pause : play});
    `;
  },
  playing: css`
    background-image: url(${pause});
  `,
  paused: css`
    background-image: url(${play});
  `,
  placeName: css`
    margin-left: 16px;
    font-size: 1.5rem;
    color: ${theme.colors.white};
    font-family: ${theme.fonts.sofiaPro};
  `,
  audioPlayer: css`
    width: 50px !important;
  `,
};
