import { css, SerializedStyles } from "@emotion/react";
import theme from "../../../theme";

export default {
  fileContainer(width: number): SerializedStyles {
    return css`
      width: ${width}%;
      height: fit-content;
      border: none;
      font-family: ${theme.fonts.sofiaPro};
      color: ${theme.colors.white};
      background-color: ${theme.colors.secondary.darkGrayCardBackground};
      padding: 30px 34px;
      display: flex;
      justify-content: space-between;
      min-width: 290px;
    `;
  },
  detailsContainer: css`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 16px;
    align-self: center;
  `,
  detailsColumn: css`
    padding: 0 8px;
  `,
  documentIcon: css`
    width: 48px;
    height: 48px;
  `,
  fileDetails: css`
    display: flex;
    margin-right: 24px;
  `,
  header: css`
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    color: ${theme.colors.secondary.textGrey};
  `,
  placeholderValue: css`
    height: 1.125rem;
  `,
  overflowButton: css`
    background-color: transparent;
    padding: 0;
    border: none;
    cursor: pointer;

    &:hover path {
      fill: ${theme.colors.secondary.brightOrange};
    }
  `,
  downloadButton: css`
    cursor: pointer;

    &:hover path {
      fill: ${theme.colors.secondary.brightOrange};
    }
  `,
  overflowContent: css`
    font-family: ${theme.fonts.sofiaPro};
    color: ${theme.colors.white};
  `,
  overflowHeader: css`
    margin-top: 8px;
  `,
  resetButtonStyles: css`
    background: none;
    border: 0;
    outline: none;
  `,
};
