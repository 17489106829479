export interface IOrganisationMember {
  id: string;
  key: string;
  firstName: string;
  lastName: string;
  email: string;
  organisationId: string;
  controllingOrgId: string;
  hasMusicSubscription?: boolean;
  hasProjectsSubscription?: boolean;
  isCurrentUser?: boolean;
  auth0UserId: string;
  jobTitle?: string;
  phoneNumber?: string;
  country?: string;
  companyName?: string;
  companyUrl?: string;
  useProjectsWhitelist: boolean;
  projectsWhitelist: string[];
  applications: eApplicationType[];
  profileImage: string;
  Images: {
    profileImage?: string;
  };
}

export enum eApplicationType {
  Portal = "Portal",
  ProjectsInternal = "ProjectsInternal",
  LibraryFull = "LibraryFull",
  ProjectsClient = "ProjectsClient",
  LibraryReadOnly = "LibraryReadOnly",
  Validate = "Validate",
  Resources = "Resources",
  Requests = "Requests",
  Insights = "Insights",
  TeamManagement = "TeamManagement",
}
