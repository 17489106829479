/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";
import { Button, Table, Tooltip } from "antd";
import copy from "copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { BackButton } from "../back-button";
import { successToast } from "../toast-notification";
import style from "./styles";
import { ISonicBranding } from "../../interfaces";
import "antd/dist/antd.min.css";
import CopyLink from "../../assets/icons/copyLink";
import theme from "../../theme";
import HeadingWithDivider from "../HeaderWithDivider/HeadingWithDivider";

interface IProps {
  sonicBrandingItems: ISonicBranding[];
}

const LandingPage = ({ sonicBrandingItems }: IProps): ReactElement => {
  const navigate = useNavigate();

  const columns = [
    {
      title: "Project",
      dataIndex: "name",
      key: "name",
      width: "20%",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "50%",
    },
    {
      title: "Updated",
      dataIndex: "lastUpdated",
      key: "lastUpdated",
      width: "12.5%",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: "12.5%",
    },
  ];

  const getUrlName = (value: string) => {
    let urlName = value;
    urlName = urlName.replace(/\s+/g, "-").toLowerCase();
    encodeURIComponent(urlName);
    return urlName;
  };

  const data = sonicBrandingItems.map((item) => {
    return {
      id: item.id,
      key: item.id,
      type: item.type,
      name: item.name,
      description: item.description,
      lastUpdated: format(parseISO(item.lastUpdated), "dd MMMM yyyy"),
      actions: (
        <div css={style.tableActions}>
          <Tooltip
            getPopupContainer={(triggerNode) => triggerNode}
            title="Copy link"
            color={theme.colors.background.white}
            placement="top"
            css={style.tooltip}
          >
            <div
              css={style.copyButton}
              onClick={(e: any) => {
                e.stopPropagation();
                copy(
                  `${window.location.origin}/assets/${item.id}/${getUrlName(
                    item.name
                  )}`
                );
                successToast({
                  message: "Copied to clipboard",
                  toastId: "success-toast",
                });
              }}
              onKeyDown={(e: any) => {
                e.stopPropagation();
                copy(
                  `${window.location.origin}/assets/${item.id}/${getUrlName(
                    item.name
                  )}`
                );
                successToast({
                  message: "Copied to clipboard",
                  toastId: "success-toast",
                });
              }}
              tabIndex={0}
              role="button"
            >
              {" "}
              <CopyLink />
            </div>
          </Tooltip>
          <Button
            onClick={() =>
              navigate(`/${item.type}/${item.id}/${getUrlName(item.name)}`)
            }
            css={style.viewButton}
          >
            View Page
          </Button>
        </div>
      ),
    };
  });

  return (
    <>
      <div css={style.backBtnContainer}>
        <BackButton />
      </div>
      <HeadingWithDivider
        secondaryHeading="Resources"
        primaryHeading="Your creative repository."
      />
      <div css={style.sectionHeader}>Browse your team resources</div>
      <Table
        css={style.table}
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered={false}
      />
    </>
  );
};

export default LandingPage;
