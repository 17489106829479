/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";
import { Button } from "antd";
import style from "./styles";

interface IProps {
  buttonText: string;
  linkTo: string;
  openInNewWindow: boolean;
  isLarge: boolean;
  buttonTheme: string;
}

const CustomButton = ({
  buttonText,
  linkTo,
  openInNewWindow,
  isLarge,
  buttonTheme,
}: IProps): ReactElement => {
  const onButtonClick = () => {
    if (!openInNewWindow) {
      window.open(linkTo, "_self");
    } else {
      window.open(linkTo, "_blank");
    }
  };

  return (
    <Button
      css={style.button(buttonTheme, isLarge)}
      onClick={() => onButtonClick()}
    >
      {buttonText}
    </Button>
  );
};

export default CustomButton;
