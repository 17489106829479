import { ReactElement } from "react";

const VolumeIcon = ({ fill = "white" }): ReactElement => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5855 0.799109L4.93143 5.83375H0.245883C0.110156 5.83375 0 5.94963 0 6.09241V14.0319C0 14.1726 0.107205 14.2874 0.240965 14.29L5.25403 14.3919L11.5855 19.1819C11.7483 19.305 11.975 19.1829 11.975 18.9718V1.00914C11.975 0.798591 11.7483 0.675985 11.5855 0.799109ZM4.53753 12.0262C4.53753 12.169 4.42737 12.2848 4.29165 12.2848H2.21295C2.07624 12.2848 1.96559 12.1674 1.96707 12.0241L1.99952 8.15914C2.001 8.0174 2.11066 7.90306 2.24541 7.90306H4.29165C4.42737 7.90306 4.53753 8.01843 4.53753 8.16173V12.0262ZM10.0079 14.9351C10.0079 15.1457 9.78123 15.2683 9.61846 15.1451L6.60688 12.8668C6.54246 12.8182 6.50459 12.7401 6.50459 12.6568V7.32521C6.50459 7.24192 6.54246 7.1638 6.60688 7.11517L9.61846 4.83634C9.78123 4.71322 10.0079 4.83531 10.0079 5.04638V14.9351Z"
        fill={fill}
      />
      <path
        d="M16.512 17.7406L15.6071 16.7887C15.4758 16.6506 15.4734 16.4281 15.6002 16.2848C18.8454 12.6149 18.8454 6.89944 15.6002 3.22951C15.4734 3.08621 15.4758 2.86376 15.6071 2.72564L16.512 1.77375C16.6487 1.62993 16.8724 1.632 17.0052 1.78048C20.9984 6.25433 20.9984 13.2605 17.0052 17.7344C16.8729 17.8828 16.6492 17.8849 16.512 17.7411V17.7406Z"
        fill={fill}
      />
      <path
        d="M14.0758 15.178L13.1705 14.2256C13.0417 14.09 13.0353 13.8722 13.1562 13.73C14.0847 12.6368 14.5927 11.239 14.5927 9.7574C14.5927 8.27577 14.0852 6.87743 13.1562 5.78483C13.0348 5.64205 13.0417 5.42425 13.1705 5.28923L14.0758 4.33683C14.2155 4.18991 14.4427 4.19715 14.5755 4.35079C15.8575 5.83139 16.5593 7.73671 16.5593 9.7574C16.5593 11.7781 15.8575 13.6834 14.5755 15.164C14.4427 15.3176 14.215 15.3249 14.0758 15.178Z"
        fill={fill}
      />
    </svg>
  );
};

export default VolumeIcon;
