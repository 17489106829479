/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";
import styles from "./styles";

interface ITextSectionProps {
  title?: string;
  description: string;
  width?: number;
}

const TextSection = ({
  title,
  description,
  width,
}: ITextSectionProps): ReactElement => {
  return (
    <div css={styles.textSection}>
      {title ? <div css={styles.title(width || 100)}>{title}</div> : <div />}
      <div css={styles.description(width || 100)}>{description}</div>
    </div>
  );
};

export default TextSection;
