/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";
import Loading from "../../assets/icons/loading";
import style from "./styles";

const LoadingSpinner = (): ReactElement => {
  return (
    <div
      css={style.loadingContainer}
      data-testid="portal-loading-spinner"
      id="portal-loading-spinner"
    >
      <div css={style.loading}>
        <Loading />
      </div>
    </div>
  );
};

export default LoadingSpinner;
