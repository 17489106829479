/** @jsxImportSource @emotion/react */
import React from "react";
import styles from "./styles";

export interface IHeadingWithDivider {
  primaryHeading?: string;
  secondaryHeading?: string;
}

const HeadingWithDivider = ({
  primaryHeading,
  secondaryHeading,
  ...rest
}: IHeadingWithDivider) => {
  return (
    <div css={styles.headingContainer} {...rest}>
      {secondaryHeading && (
        <h2 css={styles.secondaryHeading}>{secondaryHeading}</h2>
      )}
      {primaryHeading && <h1 css={styles.primaryHeading}>{primaryHeading}</h1>}
      <div css={styles.horizontalDivider} />
    </div>
  );
};

export default HeadingWithDivider;
