import { ReactElement } from "react";

const CopyLink = (): ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.71796 16.1318C8.05668 17.7766 5.35315 17.7766 3.69124 16.1318C2.02934 14.4869 2.04297 11.823 3.70426 10.1782L3.77861 10.1046C3.89945 9.98495 3.89945 9.79046 3.77861 9.67083L2.46186 8.36711C2.34103 8.24747 2.1446 8.24747 2.02376 8.36711L2.0027 8.38797C-0.621523 10.9862 -0.685967 15.2158 1.92276 17.8287C4.54202 20.4521 8.82938 20.4613 11.4604 17.8563L15.0395 14.3127C15.1604 14.193 15.1604 13.9986 15.0395 13.8789L13.7283 12.5807C13.6069 12.4605 13.4105 12.4611 13.2896 12.5807L9.71796 16.1318ZM18.0064 1.90372C15.3673 -0.679175 11.0954 -0.615369 8.47123 1.98287L4.9448 5.47438C4.82396 5.59402 4.82396 5.7885 4.9448 5.90814L6.25598 7.20634C6.37743 7.32658 6.57386 7.32597 6.69469 7.20634L10.2664 3.65592C11.9276 2.01109 14.6312 2.01109 16.2931 3.65592C17.9544 5.30076 17.9407 7.96403 16.2794 9.60947L16.2051 9.6831C16.0843 9.80273 16.0843 9.99722 16.2051 10.1169L17.5218 11.4206C17.6427 11.5402 17.8391 11.5402 17.9599 11.4206L18.0337 11.3476C20.6647 8.74258 20.6554 4.49828 18.0058 1.90434L18.0064 1.90372Z"
      fill="white"
    />
    <path
      d="M12.8094 5.3678L5.42025 12.6838C5.29926 12.8036 5.29926 12.9978 5.42025 13.1176L6.73473 14.419C6.85572 14.5388 7.05189 14.5388 7.17289 14.419L14.562 7.10309C14.683 6.98329 14.683 6.78906 14.562 6.66926L13.2475 5.3678C13.1265 5.24801 12.9304 5.24801 12.8094 5.3678Z"
      fill="white"
    />
  </svg>
);

export default CopyLink;
