import { css, keyframes } from "@emotion/react";

const rotate360 = keyframes`
  0% {
    -webkit-transform: scale(0.9, 0.9);
    opacity: 0.7;
  }
  50% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9, 0.9);
    opacity: 0.7;
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  loadingContainer: css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
  `,
  loading: css`
    animation: ${rotate360} 1s linear infinite;
    transform-box: fill-box;
    transform-origin: 50% 50%;
    width: 32px;
    height: 32px;
  `,
};
