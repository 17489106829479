/** @jsxImportSource @emotion/react */
import { useNavigate } from "react-router-dom";
import ArrowLeft from "src/assets/icons/arrowLeft";
import { IProps } from "./interfaces";
import styles from "./styles";

export const BackButton = ({ onClick, className }: IProps) => {
  const navigate = useNavigate();

  const buttonOnClick = () => {
    if (onClick) {
      onClick();
      return;
    }
    navigate(-1);
  };

  return (
    <button
      css={styles.buttonContainer}
      onClick={buttonOnClick}
      data-testid="back-button"
      data-heapid="Back"
      type="button"
      className={className}
    >
      <div css={styles.span}>
        <ArrowLeft />
      </div>
      <div className="buttonText">Back</div>
    </button>
  );
};

export default BackButton;
