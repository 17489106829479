import config from "src/config";

const buildOrganisationsBaseUrl = (organisationId: string): string => {
  return `${config.organisationSubscriptionsService.url}/organisations/${organisationId}`;
};

const getOrganisationUserUrl = (
  organisationId: string,
  userId: string
): string => {
  return `${buildOrganisationsBaseUrl(organisationId)}/members/${userId}`;
};

export default getOrganisationUserUrl;
