import React, { useEffect, useMemo, useState } from "react";
import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
  CookieStorage,
} from "amazon-cognito-identity-js";
import config from "src/config";
import getOrgMember from "src/api/get-org-member";
import {
  getOrgIdsFromDecodedToken,
  getUserPermissions,
} from "src/api/get-org-member/utils";
import { CognitoContext } from "./Context";
import {
  IEnterpriseUser,
  IGuestUser,
  IUserPermissions,
  parseEnterpriseJWT,
  parseGuestJWT,
} from "../Platform/EnterprisePlatform";
import { IGuestLogin, guestLogin } from "../api";
import { ENV } from "../utils/env";

const cookieStorage = new CookieStorage({
  domain: ENV.COOKIE_DOMAIN,
  secure: true,
});

const guestTokenKey = "enterprise_guest_token";
// es-lint-disable no-explicit-any
const CognitoProvider = ({ children, clientId, userPoolId }: any) => {
  const [organisationId, setOrganisationId] = useState("");
  const [hasLoaded, setHasLoaded] = useState(false);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser>();
  const [user, setUser] = useState<IEnterpriseUser>();
  const [guestUser, setGuestUser] = useState<IGuestUser>();
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [profileImage, setProfileImage] = useState<undefined | string>("");
  const [userPermissions, setUserPermissions] = useState<IUserPermissions>({
    canAccessPortal: false,
    canAccessInsights: false,
    canAccessMyContent: false,
    canAccessRequests: false,
    canAccessValidate: false,
    canAccessTeams: false,
    canAccessProjects: false,
    canAccessMusicSubscription: false,
  });

  const userPool = useMemo(() => {
    return new CognitoUserPool({
      UserPoolId: userPoolId,
      ClientId: clientId,
      Storage: new CookieStorage({
        domain: config.cognito.domain,
        secure: true,
      }),
    });
  }, [clientId, userPoolId]);

  const login = (username: string, password: string) => {
    return new Promise<void>((resolve, reject) => {
      const authenticationDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      });

      const userData = {
        Username: username,
        Pool: userPool,
        Storage: cookieStorage,
      };

      const userLogin = new CognitoUser(userData);

      const onLoginFailure = <T extends { message: string }>(result: T) => {
        reject(result.message);
      };

      const onLoginSuccess = () => {
        setCognitoUser(userLogin);
        resolve();
      };

      userLogin.authenticateUser(authenticationDetails, {
        onSuccess: onLoginSuccess,
        onFailure: onLoginFailure,
        newPasswordRequired: () => {
          userLogin.completeNewPasswordChallenge(password, null, {
            onSuccess: onLoginSuccess,
            onFailure: onLoginFailure,
          });
        },
      });
    });
  };

  const getUserAccessToken = async () => {
    let jwt;
    const cognitoUserSession = userPool.getCurrentUser();

    if (cognitoUserSession) {
      cognitoUserSession.getSession((err: any, session: CognitoUserSession) => {
        if (session) {
          const token = session.getAccessToken();
          jwt = token.getJwtToken();
        }
      });
    }
    return jwt || "";
  };

  // Required from old auth provider
  const saveData = (key: string, value: string) => {
    window.localStorage.setItem(key, value);
  };

  // Required from old auth provider
  const loadData = (key: string) => {
    return window.localStorage.getItem(key) || "";
  };

  const hasSuperAdminRole = (decodedToken: { [id: string]: any }) => {
    const cognitoGroups: string[] = decodedToken["cognito:groups"];
    return cognitoGroups.includes("superadmin");
  };

  const logout = () => {
    if (cognitoUser) {
      cognitoUser?.signOut(() => {
        setUser(undefined);
        cookieStorage.removeItem("portal-organisationId");
        setOrganisationId("");
        window.location.replace(`${config.enterprise.url}/login`);
      });
    }
  };

  const loginGuest = async (guid: string) => {
    const guest = (await guestLogin(guid)) as IGuestLogin;
    setGuestUser(parseGuestJWT(guest.jwt));
    saveData(guestTokenKey, guest.jwt);
    setHasLoaded(true);
  };

  useEffect(() => {
    const currentUser = userPool.getCurrentUser();

    // No user session, so check if there's a guest token instead
    if (!currentUser) {
      const guestJwt = loadData(guestTokenKey);

      if (guestJwt) {
        setGuestUser(parseGuestJWT(guestJwt));
      }

      setHasLoaded(true);
      return;
    }

    setCognitoUser(currentUser);
  }, [userPool]);

  useEffect(() => {
    if (!cognitoUser) {
      return;
    }

    cognitoUser.getSession(
      async (err: Error, session: CognitoUserSession | null) => {
        if (err) {
          return;
        }

        if (!session) {
          return;
        }

        const userIsAuthenticated = session.isValid();

        if (!userIsAuthenticated) {
          return;
        }

        const accessToken = session.getAccessToken();
        const jwt = accessToken.getJwtToken();
        const decodedToken = accessToken.decodePayload();
        if (hasSuperAdminRole(decodedToken)) {
          setIsSuperAdmin(true);
        }
        const orgIds = getOrgIdsFromDecodedToken(decodedToken);
        let orgId = orgIds.length === 1 ? orgIds[0] : null;
        const previousOrgId = cookieStorage.getItem("portal-organisationId");
        if (
          previousOrgId &&
          (hasSuperAdminRole(decodedToken) ||
            orgIds.some((x) => x === previousOrgId))
        ) {
          orgId = previousOrgId;
        }
        if (orgId) {
          const enterpriseUser = parseEnterpriseJWT(jwt, session.getIdToken());
          const orgMember = await getOrgMember(jwt, orgId, decodedToken.sub);
          setProfileImage(orgMember?.profileImage);
          const permissions = getUserPermissions(
            hasSuperAdminRole(decodedToken),
            orgMember.applications
          );
          setUserPermissions(permissions);
          setUser(enterpriseUser);
          setHasLoaded(true);
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cognitoUser]);

  return (
    <CognitoContext.Provider
      value={{
        login,
        logout,
        loginGuest,
        hasLoaded,
        pretzelUser: user,
        saveData,
        loadData,
        userPermissions,
        getUserAccessToken,
        organisationId,
        setOrganisationId,
        isSuperAdmin,
        profileImage,
        user,
        guestUser,
      }}
    >
      {children}
    </CognitoContext.Provider>
  );
};

export default CognitoProvider;
